import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService, LoginService } from 'src/app/services';
import { SearchDisplayModel } from 'src/app/common/models/searchDisplayModel';
import { SearchService } from 'src/app/services/search.service';
import { ConnectionListService } from '../../services/shared-services/connection-list.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'haven-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() loggingOut = new EventEmitter();
  constructor(private authService: AuthService,
              public connectionService: ConnectionListService, private tokenService: TokenService, private loginService: LoginService
             ) {
  }
  userName = '';

  ngOnInit() {
    this.connectionService.setConnectionList();
    this.userName = this.authService.getUserName();
  }

  logout() {
    this.loginService.logout().subscribe(
      (result) => {
        this.loggingOut.emit();
        this.authService.deleteCookies();
        this.authService.logout();
      }
    );
  }

  onConnectionChange(connectionGuid: string) {
   this.connectionService.setSubject(connectionGuid);
  }
  
}
