import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DashboardService, NotificationService } from 'src/app/services';
import { ResponseMessageType } from 'src/app/common/enum';
import appConstant from '../../../assets/app-text.json';
import workSpaceConstant from '../../../assets/workspace-security.json';
import workSpaceDataConstant from '../../../assets/workspace-security-data.json';
import { WorkSpaceSecurityRequestModel, MatterFolderSecurityRequestModel } from 'src/app/common/models/workSpaceSecurityRequestModel';
import { WorkSpaceSecurityResponseModel } from 'src/app/common/models/workSpaceSecurityResponseModel';

@Component({
  selector: 'haven-workspace-security-accessors',
  templateUrl: './workspace-security-accessors.component.html',
  styleUrls: ['./workspace-security-accessors.component.scss'],
})
export class WorkspaceSecurityAccessorsComponent implements OnInit, OnChanges {
  @Input() docInfo;
  isLoading = false;
  workSpaceSecurityData: WorkSpaceSecurityResponseModel;
  appConstant;
  workSpaceConstant;
  @Input()
  isEmail?: boolean;
  @Input() isMatter?: boolean;
  @Input() isFolder?: boolean;
  isSecurityDetailsToBeShown = false;
  workSpaceSecurityRequest: WorkSpaceSecurityRequestModel;
  matterFolderSecurityModel: MatterFolderSecurityRequestModel;
  constructor(
    private dashboardService: DashboardService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.appConstant = appConstant;
    this.workSpaceConstant = workSpaceConstant;
    this.workSpaceSecurityData = new WorkSpaceSecurityResponseModel();
  }
  ngOnChanges(changes) {
    if (changes && changes.docInfo && changes.docInfo.currentValue && this.isMatter) {
      this.matterFolderSecurityModel = new MatterFolderSecurityRequestModel();
      this.matterFolderSecurityModel.itemNumber = this.docInfo.matterNumber;
      this.matterFolderSecurityModel.libraryGuid = this.docInfo.libraryGuid;
      this.getWorkSpaceSecurityData(this.matterFolderSecurityModel, this.isMatter);
    } else if (changes && changes.docInfo && changes.docInfo.currentValue && this.isFolder) {
      this.matterFolderSecurityModel = new MatterFolderSecurityRequestModel();
      this.matterFolderSecurityModel.itemNumber = this.docInfo.number;
      this.matterFolderSecurityModel.libraryGuid = this.docInfo.libraryGuid;
      this.getWorkSpaceSecurityData(this.matterFolderSecurityModel, this.isFolder);
    } else if (changes && changes.docInfo && changes.docInfo.currentValue) {
      this.workSpaceSecurityRequest = new WorkSpaceSecurityRequestModel();
      this.workSpaceSecurityRequest.number = this.docInfo.docNum;
      this.workSpaceSecurityRequest.libraryGuid = this.docInfo.libraryGuid;
      this.workSpaceSecurityRequest.version = this.docInfo.version;
      this.getWorkSpaceSecurityData(this.workSpaceSecurityRequest, this.isMatter);
    }
  }
  getWorkSpaceSecurityData(req, isMatterOrFolder) {
    this.isLoading = true;
    this.dashboardService.getWorkspaceSecurityData(req, isMatterOrFolder).subscribe(
      (result: any) => {
        this.isLoading = false;
        if (result.message === ResponseMessageType.SUCCESS) {
          this.workSpaceSecurityData = result.data;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
}
