import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { DashboardService } from '../../services/index';
import { ResponseMessageType } from 'src/app/common/enum/responseMessageType';
import { WorkspaceResponseModel } from 'src/app/common/models/workspaceModel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'haven-workspace-properties',
  templateUrl: './workspace-properties.component.html',
  styleUrls: ['./workspace-properties.component.scss'],
})
export class WorkspacePropertiesComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() docInfo;
  @Input() isFolder?: false;
  isLoading = false;
  workSpaceData;
  libraryGuid;
  private destroy$ = new Subject();
  constructor(private dashboardService: DashboardService, private router: Router ) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes && changes.docInfo && changes.docInfo.currentValue && this.isFolder) {
      this.libraryGuid = changes.docInfo.currentValue.libraryGuid;
      const folderLocationRequest = {
        libraryGuid: this.libraryGuid,
        itemGuid: changes.docInfo.currentValue.documentGuid
      };
      this.getWorkSpaceData(folderLocationRequest);
    } else if (changes && changes.docInfo && changes.docInfo.currentValue) {
      this.getWorkSpaceData(changes.docInfo.currentValue);
      this.libraryGuid = changes.docInfo.currentValue.libraryGuid;
    }
  }

  getWorkSpaceData(req) {
    this.isLoading = true;
    this.dashboardService
      .getWorkspaceData(req, this.isFolder)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (result: WorkspaceResponseModel) => {
          this.isLoading = false;
          if (result.message === ResponseMessageType.SUCCESS) {
            this.workSpaceData = result.data.locationsList;
          }
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  navigateToFolder(libraryGuid, matterGuid, folderGuid) {
    this.router.navigate([
      '/dashboard/matters',
      libraryGuid,
      matterGuid,
      folderGuid
    ]);
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
