export class ConnectionListModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: ConnectionList[];
  errors: any;
  }

export class ConnectionList {
  connectionGuid: string;
  connectionName: string;
  dataCenterGuid: string;
  dataCenterName: string;
  dataCenterUrl: string;
  }
