import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { configJSON } from '../appConfig';
import { AuthService } from './auth.service';
import { ResponseMessageType } from '../common/enum';
import appConstant from '../../assets/app-text.json';

@Injectable({
  providedIn: 'root',
})
export class DocumentEmailDownloadService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  getEmailOrDownloadDocument(documentInfo: any) {
    const docSizeInMb = documentInfo.data.description.size / 1024;
    if (
      this.authService.getMaxFileSize() !== 0 &&
      docSizeInMb > this.authService.getMaxFileSize()
    ) {
      this.notificationService.error(
        appConstant.MAX_FILE_SIZE_DOWNLOAD +
          this.authService.getMaxFileSize() +
          ' MB'
      );
      return;
    }
    const request = {
      itemGuid: documentInfo.data.description.documentGuid,
      itemType: documentInfo.data.itemType,
      itemSubType: documentInfo.data.description.extension,
      EmailAddress: this.authService.getUserEmail(),
      downloadType: documentInfo.event,
    };
    this.http
      .post<any>(configJSON.getDownloadDocument, request,{withCredentials: true})
      .subscribe((response) => {
        if ((response && response.message === ResponseMessageType.SUCCESS) && response.data) {
          if (documentInfo.event === 'Direct') {
            window.open(response.data.downloadUrl, '_self');
          }
          this.notificationService.success(response.data.message);
        }
      });
  }
}
