<div class="container-fluid pl-15 pr-15 main-wrapper-header">
  <div class="row">
    <div class="col-4">
      <img
        src="../../../assets/images/HAVENFullColor.svg"
        class="haven-client-logo"
      />
    </div>
    <div class="col-4">
     <haven-search></haven-search>
    </div>
    <div class="col-4 user-info">
      <div class="dropdown user-dropdown col-sm-10">
        <span class="input-label">Connection</span>
            <div class="dropdown display-inline">
              <select [disabled] ="connectionService.isConnectionDisable"  class="select-dropdown" (change) ="onConnectionChange($event.target.value)">
                <option *ngFor="let connection of connectionService.userConnectionList"
                  [value]="connection.connectionGuid"
                 >
                 {{connection.connectionName}}
                </option>
              </select>
            </div>
        <button
          class="btn dropdown-toggle input-label"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ userName }}
        </button>
        <div
          class="dropdown-menu dropdown-menu-right input-label"
          aria-labelledby="dropdownMenu2"
        >
          <button class="dropdown-item" type="button" (click)="logout()">
            <span><i class="fa fa-sign-out"></i></span> Logout
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
