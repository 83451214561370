export class AuthenticateUserRequestModel {
  jwtToken: string;
  accessCode: string;
}
export class ValidUserModel {
  jwtToken: string;
  userAccessCode: string;
  userId: string;
  userAccessCodeId: number;
}
export class UserContact {
  isSms: boolean;
  isEmail: boolean;
}
export class AuthenticatedUserModel {
  userGuid: string;
  clientGuid: string;
  userRoleName: string;
  loggedInName: string;
  errorMessage: string;
  primaryEmailAddress: string;
  secondaryEmailAddress: string;
  iManageUserId: string;
  contact: UserContact;
  isTestUser: boolean;
  isAllowLogin: boolean;
  mobile: string;
  accessCode: string;
  expiration: string;
  expirationInMinutes: number;
  isDownloadEmailEnabled: boolean;
  isDownloadDirectEnabled: boolean;
  maxMbFileDownload: number;
  jwtToken: string;
  userPasswordEncrypted: string;
}
