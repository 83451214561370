<header *ngIf="isHeaderVisible">
  <haven-header (loggingOut)="loggedOut()"></haven-header>
  <haven-menu></haven-menu>
  <haven-timer (TimerExpired)="idleTimeExpired()" class="display-none"></haven-timer>
</header>

<div class="d-flex" id="wrapper" havenEvent>
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</div>
