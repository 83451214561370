<div class="login-theme">
  <haven-loader [loaderEnabled]="isLoading"></haven-loader>

  <div class="headerContainer">
    <div class="row" style="padding: 15px;">
      <div class="col-lg-12">
        <div class="logo-div">
        <img
          height="45px"
          src="./assets/images/HAVENFullColor.svg"
        />
        </div>
        <span class="login-header-title">{{
          appConstant.LOGIN_FORM_HEADER_TITLE
        }}</span>
      </div>
    </div>
    <form
      #loginForm="ngForm"
      name="form"
      (ngSubmit)="
        loginModel.accessCode == ''
          ? loginForm.form.valid && getAccessCode()
          : loginForm.form.valid && onSubmit()
      "
      class="needs-validation"
      novalidate
    >
      <div class="row">
        <div class="col-lg-12 formcontrol">
          <div
            class="col-12 form-group"
            [ngClass]="{
              'has-danger': email.invalid && (email.dirty || email.touched),
              'has-success': email.valid && (email.dirty || email.touched)
            }"
          >
            <label for="email">{{
              appConstant.LOGIN_FORM_EMAIL_ADDRESS_TITLE
            }}</label>
            <a
              href="javascript:void(0);"
              class="resend float-right"
              (click)="changeEmailAddress()"
              *ngIf="isAccessCodeSent"
              tabindex="7"
              >{{ appConstant.CHANGE_EMAIL_ADDRESS }}</a
            >
            <input
              type="email"
              name="email"
              class="form-control"
              placeholder="someone@example.com"
              [(ngModel)]="loginModel.email"
              #email="ngModel"
              required
              haven-emailValidator
              [disabled]="isAccessCodeSent"
              maxlength="256"
              autocomplete="off"
              tabindex="1"
            />
            <a
              href="javascript:void(0);"
              class="reset-link"
              [ngClass]="{ adisabled: isAccessCodeSent }"
              *ngIf="loginModel.email != ''"
              (click)="!isAccessCodeSent && clearEmail()"
              tabindex="5"
              
            >
              <span>&times;</span></a
            >
            <div
              *ngIf="email.errors && (email.dirty || email.touched)"
              class="form-control-feedback text-danger"
            >
              <div *ngIf="email.errors.required">
                {{ appConstant.REQUIRED_VALIDATION_MESSAGE }}
              </div>
              <div
                *ngIf="email.errors.emailValidator && !email.errors.required"
              >
                {{ appConstant.INVALID_EMAIL_VALIDATION_MESSAGE }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <span
              class="col-lg-12 access-code p-0"
              *ngIf="isAccessCodeSent"
              >{{ appConstant.LOGIN_FORM_ACCESS_CODE_SENT_MESSAGE }}</span
            >
            <button
              type="button"
              [disabled]="loginModel.email == '' || email.errors"
              class="btn btn-primary float-right access-button mt-2"
              (click)="getAccessCode()"
              tabindex="2"
              *ngIf="!isAccessCodeSent"
            >
              {{ appConstant.GET_ACCESS_CODE_BUTTON_TITLE }}
            </button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isAccessCodeSent">
        <div class="col-lg-12 formcontrol">
          <div
            class="col-12 form-group"
            [ngClass]="{
              'has-danger':
                accessCode.invalid && (accessCode.dirty || accessCode.touched),
              'has-success':
                accessCode.valid && (accessCode.dirty || accessCode.touched)
            }"
          >
            <label for="accessCode">{{
              appConstant.LOGIN_FORM_ACCESS_CODE_TITLE
            }}</label>
            <a
              href="javascript:void(0);"
              class="resend float-right"
              (click)="getAccessCode()"
              tabindex="8"
              >{{ appConstant.RESEND_CODE_BUTTON_TITLE }}</a
            >

            <input
              type="password"
              name="accessCode"
              class="form-control"
              placeholder="Access Code"
              #accessCode="ngModel"
              required
              minlength="6"
              maxlength="6"
              pattern="^[A-Z a-z]{6}$"
              [(ngModel)]="loginModel.accessCode"
              tabindex="3"
              autocomplete="off"
              [disabled]="isFormSubmitted"
            />
            <a
              href="javascript:void(0);"
              class="reset-link"
              *ngIf="loginModel.accessCode != ''"
              (click)="clearAccessCode()"
              [ngClass]="{ adisabled: isFormSubmitted }"
              tabindex="6"
             
            >
              <span>&times;</span></a
            >
            <div
              *ngIf="
                accessCode.errors && (accessCode.dirty || accessCode.touched)
              "
              class="form-control-feedback text-danger"
            >
              <div *ngIf="accessCode.errors.required">
                {{ appConstant.REQUIRED_VALIDATION_MESSAGE }}
              </div>
              <div
                *ngIf="accessCode.errors.pattern && !accessCode.errors.required"
              >
                {{ appConstant.INVALID_ACCESS_CODE_VALIDATION_MESSAGE }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <button
              type="submit"
              class="btn btn-primary access-button mt-2"
              tabindex="4"
              [disabled]="!loginForm.form.valid && isFormSubmitted"
            >
              {{ appConstant.SIGN_IN_BUTTON_TITLE }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!-- <div class="loaderContainer">
    <div class="loader-txt"></div>
    <div class="loader"></div>
  </div> -->
</div>
