import { Injectable } from '@angular/core';
import  menuConfig from '../../assets/menu.json';

import { Menu } from '../common/models/menu';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() {}
  getAllMenus(): Menu[] {
    const items: Menu[] = [];
    const menus = menuConfig.Menus;
    menus.forEach((menu) => {
      items.push(this.populateMenu(menu));
    });
    return this.MenuRestructorization(items);
  }
  MenuRestructorization(items: Menu[]) {
    let menus: Menu[] = [];
    menus = items;
    return menus;
  }

  private populateMenu(menu: any): Menu {
    return new Menu(menu.displayName, menu.url, menu.order, menu.id, menu.img, menu.activeImg, menu.hide);
  }
}
