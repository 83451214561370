
<div class="bg-filter-column border-right" style="float: left;" id="sidebar-wrapper">
    <haven-loader [loaderEnabled]="isLoading"></haven-loader>
    <div *ngIf="!filterTitle" class="header-title">Filters</div>
    <div *ngIf="filterTitle" class="header-title">All Document</div>
    <div class="list-group list-group-flush">
        <div id="Filter" *ngFor="let filter of filterData; let i = index">
        <div class="filter-set">
            <div class="filter-title-border">
                <a class="filter-position filter-title " data-toggle="collapse" href="#collapse{{i}}" [ngClass]="{'menu-disable': libraryGuid && filter.selectType == 'multiple'}"> {{filter.name}}
                </a>
            </div>
            <div id="collapse{{i}}" class="dummyclass panel-collapse collapse">
                <div class="list-item" *ngFor="let data of filter.value">
                    <!-- data-toggle="collapse" data-target=".dummyclass" -->
                    <div class="list-item" (click)="onSelectFilter(filter, data, filter.selectType)" [ngClass]="{'menu-disable': libraryGuid && filter.selectType == 'multiple'}" >
                        <div style="float: left;" *ngIf="data.isSelected" id=""
                            class="pie image lockV firer ie-background commentable" datasizewidth="10px" alt="image"
                            systemname="./images/32cc2575-a89e-4810-94b8-65d962338a0c.svg" overlay="#214F9B">
                            <img src="../../../assets/images/check-leftpanel.svg">
                        </div>
                        <span title="{{data.name}}"
                            [ngClass]="data.isSelected ? 'selected-option': 'non-selected-option'">{{data.name}}</span>
                    </div>
                </div>
            </div>
            <div id="collapse{{i}}" class="dummyclass panel-collapse collapse show">
                <ng-container *ngFor="let data of filter.value">
                    <div class="pie image lockV firer ie-background commentable selected-value-tick"
                        datasizewidth="10px" alt="image" systemname="./images/32cc2575-a89e-4810-94b8-65d962338a0c.svg"
                        overlay="#214F9B" *ngIf="data.isSelected">
                        <img src="../../../assets/images/check-leftpanel.svg">
                    </div>
                    <div class="selected-value" *ngIf="data.isSelected"><span>{{data.name}}</span></div>
                </ng-container>
            </div>
        </div>
        </div>

<!-- ===================START Child Filter=========================== -->

<!-- ===================END Child Filter=========================== -->

    </div>
</div>