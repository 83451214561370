export class Library {
  isSelected: boolean;
  libraryGuid: string;
  name: string;
}
export class LibraryResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: Library[] = [];
  dataEnumerable: any[] = [];
  errors: any[] = [];
}

export class LibraryReuestModel {
  connectionGuid: string;
}
