import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { ResponseModel } from '../common/models/responseModel';
import { configJSON } from '../appConfig';
import { HttpClient } from '@angular/common/http';
import { ResponseMessageType } from '../common/enum';
import appConstant from '../../assets/app-text.json';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  public subject = new Subject<any>();
  public refreshTokenSubject = new Subject<any>();

  public idleTimeSubject = new Subject<any>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private http: HttpClient,
    private notificationService: NotificationService
  ) {}
  timerRefresh() {
    this.idleTimeSubject.next({ isTimerToBeRefreshed: true });
  }
  getTimerRefreshMessage(): Observable<any> {
    return this.idleTimeSubject.asObservable();
  }
  tokenRefreshed() {
    this.refreshTokenSubject.next({ isTokenRefreshed: true });
  }
  getRefreshTokenMessage(): Observable<any> {
    return this.refreshTokenSubject.asObservable();
  }
  checkTokenValidity() {
    if (
      this.router.url.indexOf('login') === -1 &&
      (!this.authService.getClientId()  ||
        !this.authService.getIManageUserId() ||
        !this.authService.getUserId())
    ) {
      this.authService.logout();
    } else {
      this.timerRefresh();
    }
  }
  getRefreshedToken(req: { emailAddress: string }): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(configJSON.refreshToken, req,{withCredentials: true});
  }
  refreshToken(email: string) {
    const req = {
      emailAddress: email,
    };
    this.getRefreshedToken(req).subscribe((result) => {
      if (!!result) {
        if (result.message === ResponseMessageType.SUCCESS) {
          this.authService.updateRefreshToken(result.data);
        }
      }
    });
  }
}
