import { Injectable } from '@angular/core';
import { Subject, Observable, from } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ValidUserModel, AuthenticatedUserModel } from '../common/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private cookieService: CookieService) {}
  private authServiceSubject = new Subject<any>();
  getClientUserData(): AuthenticatedUserModel {
    const clientUserData = this.getCookie('client_user_data');
    return clientUserData === ''
      ? new AuthenticatedUserModel()
      : JSON.parse(this.getCookie('client_user_data'));
  }
  getExpirationTime() {
    const s = this.getClientUserData();
    return this.getClientUserData().expiration;
  }
  getTokenLifeTime() {
    return this.getClientUserData().expirationInMinutes;
  }
  getUserRole() {
    return this.getClientUserData().userRoleName;
  }
  getClientId() {
    return this.getClientUserData().clientGuid;
  }
  getLoggedInUserContact() {
    return this.getClientUserData().contact;
  }
  isLoggedInUserATestUser() {
    return this.getClientUserData().isTestUser === true;
  }
  getMobile() {
    return this.getClientUserData().mobile;
  }
  getIManageUserId() {
    return this.getClientUserData().iManageUserId;
  }
  getUserName() {
    return this.getClientUserData().loggedInName;
  }
  getUserId() {
    return this.getClientUserData().userGuid;
  }
  getUserEmail() {
    return this.getClientUserData().primaryEmailAddress;
  }

  getMaxFileSize() {
    return this.getClientUserData().maxMbFileDownload;
  }

  isUserAllowForEmail() {
    return this.getClientUserData().isDownloadEmailEnabled === true;
  }

  isUserAllowForDownload() {
    return this.getClientUserData().isDownloadDirectEnabled === true;
  }
  // generic method different type data can be thown via subject
  setSubject(data: any) {
    this.authServiceSubject.next(data);
  }
  clearSubject() {
    return this.authServiceSubject.next();
  }
  // difffent type of data can be get from observable
  getSubject(): Observable<any> {
    return this.authServiceSubject.asObservable();
  }
  setAuthenticatedCookies(userData: AuthenticatedUserModel) {
    const expirationInMinutes = userData.expirationInMinutes;
    this.setCookie(
      'client_user_data',
      JSON.stringify(userData),
      expirationInMinutes
    );
  }
  updateRefreshToken(userData: AuthenticatedUserModel) {
    const expirationInMinutes = userData.expirationInMinutes;
    const existingUserData = this.getClientUserData();
    existingUserData.jwtToken = userData.jwtToken;
    existingUserData.expiration = userData.expiration;
    existingUserData.expirationInMinutes = userData.expirationInMinutes;
    this.cookieService.deleteAll();
    this.cookieService.deleteAll('/');
    this.deleteCookies();
    this.setCookie(
      'client_user_data',
      JSON.stringify(existingUserData),
      expirationInMinutes
    );
  }
  // setCookie is a generic method to set the cookie of any type either it may be string , number,JSON or boolean
  setCookie(key: string, value: any, expires: number = 0) {
    const date = new Date(Date.now() + expires * 60 * 1000);
    if (environment.envName === 'local') {
        document.cookie = `${key}=${value};expires=${date.toUTCString()}`;
    } else {
        document.cookie = `${key}=${value};expires=${date.toUTCString()};Secure;SameSite=None;`;

    }
  }
  getCookie(cname) {
    const name = cname + '=';
    let cookieValue = '';
    const ca: string[] = document.cookie.split(';');
    if (ca.length > 0) {
      ca.forEach((cookie) => {
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
          cookieValue = cookie.substring(name.length, cookie.length);
        }
      });
    }
    return cookieValue;
  }
  logout() {
    const returnUrl =
      this.router.url.indexOf('search=true') > -1
        ? this.router.url.split('?')[0]
        : this.router.url;
    this.setSubject(null);
    this.cookieService.deleteAll();
    this.cookieService.deleteAll('/');
    this.deleteCookies();
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl,
      },
    });
  }
  updateCookies(expirationInMinutes: number) {
    const date = new Date(Date.now() + expirationInMinutes * 60 * 1000);
    const res = document.cookie;
    if (res !== '') {
      const multiple: string[] = res.split(';');
      if (multiple.length > 0) {
        multiple.forEach((cookie) => {
          const key = cookie.split('=');
          if (environment.envName === 'local') {
              document.cookie = `${key[0]}=${key[1]};expires=${date.toUTCString()}`;
          } else {
              document.cookie = `${key[0]}=${key[1]};expires=${date.toUTCString()};Secure;SameSite=None;`;
          }
        });
      }
    }
  }
  deleteCookies() {
    const res = document.cookie;
    if (res !== '') {
      const multiple: string[] = res.split(';');
      if (multiple.length > 0) {
        multiple.forEach((cookie) => {
          const key = cookie.split('=');
          document.cookie =
            key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
        });
      }
    }
  }
}
