export enum SearchTypeEnum {
  DOCUMENT = 'Documents',
  EMAIL = 'Emails',
  MATTER = 'Matters',
  CLIENT = 'Clients',
  FOLDER = 'Folders',
}
export enum SearchCategoryType {
  TITLE = 'Title',
  AUTHOR = 'Author/Operator',
  NUMBER = 'Number',
  CLIENT = 'Client',
  MATTER = 'Matter',
  SUBJECT = 'Subject',
}
