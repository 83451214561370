import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  DoCheck,
  OnDestroy,
} from '@angular/core';
import appConstant from '../../assets/app-text.json';
import {
  LoginService,
  NotificationService,
  AuthService,
  LocalStorageService,
} from '../services';
import { ResponseMessageType } from '../common/enum/responseMessageType';
import { AuthenticateUserRequestModel } from '../common/models/authenticateUserRequestModel';
import { Router, ActivatedRoute } from '@angular/router';
import { RouteTypes } from '../common/enum/routeEventType';
import { STATUS_CODES } from 'http';

@Component({
  selector: 'haven-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, DoCheck, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {}
  isEmailRendered = false;
  isAccessCodeRendered = false;
  isLoading = false;

  appConstant;
  loginModel = { email: '', accessCode: '' };
  isValid = true;
  isAccessCodeSent = false;
  returnUrl: string;
  isFormSubmitted = false;
  @ViewChild('accessCode', { read: ElementRef })
  accessCodeField: ElementRef;
  @ViewChild('email', { read: ElementRef, static: true })
  emailField: ElementRef;
  @ViewChild('loginForm') loginForm;
  ngOnInit() {
    this.authService.deleteCookies();
    this.authService.setSubject(null);
    this.appConstant = appConstant;
    document.documentElement.setAttribute('class', 'loginHtml');
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    this.notificationService.getSubject().subscribe((result) => {
      if (result.isLoginError) {
        this.isLoading = false;
        this.loginModel.accessCode = '';
        this.isFormSubmitted = false;
        this.loginForm.reset();
        this.isAccessCodeSent = false;
      }
    });
  }
  ngOnDestroy(): void {
    document.documentElement.removeAttribute('class');
  }
  ngDoCheck(): void {
    if (!this.isEmailRendered) {
      this.isEmailRendered = !!this.emailField.nativeElement;
      if (this.isEmailRendered) {
        this.emailField.nativeElement.focus();
      }
    }
    if (!this.isAccessCodeRendered) {
      this.isAccessCodeRendered =
        this.accessCodeField && this.accessCodeField.nativeElement;
      if (this.isAccessCodeRendered) {
        this.accessCodeField.nativeElement.focus();
      }
    }
  }
  clearEmail() {
    this.loginModel.email = '';
    this.emailField.nativeElement.focus();
  }
  clearAccessCode() {
    this.loginModel.accessCode = '';
    this.accessCodeField.nativeElement.focus();
  }
  getAccessCode() {
    this.isLoading = true;
    this.loginService.getAccessCode(this.loginModel.email).subscribe(
      (result) => {
        this.isLoading = false;
        if (result.message === ResponseMessageType.SUCCESS) {
          this.isAccessCodeSent = true;
        } else if (result.message === ResponseMessageType.ERROR) {
          this.isAccessCodeSent = false;
        }
      },
      (error) => {
        this.isLoading = false;
        this.loginForm.reset();
        this.emailField.nativeElement.focus();
      }
    );
  }
  changeEmailAddress() {
    this.isAccessCodeSent = false;
  }

  resetLoginForm() {
    this.isAccessCodeSent = false;
  }

  onSubmit() {
    this.isLoading = true;
    const data = new AuthenticateUserRequestModel();
    data.accessCode = this.loginModel.accessCode;
    this.loginService.authenticateUser(data).subscribe(
      (result) => {
        if (!!result) {
          if (result.message === ResponseMessageType.SUCCESS) {
            if (
              result.data.iManageUserId &&
              result.data.iManageUserId !== ''
            ) {
              this.authService.deleteCookies();
              this.authService.setAuthenticatedCookies(result.data);
              if (
                this.localStorageService.getLocalStorage('userId') &&
                this.localStorageService.getLocalStorage('userId') ===
                  this.authService.getUserId()
              ) {
                this.localStorageService.setLocalStorage(
                  'userId',
                  result.data.userGuid
                );
              } else {
                this.localStorageService.clearLocalStorage();
                this.localStorageService.setLocalStorage(
                  'userId',
                  result.data.userGuid
                );
              }
              if (this.returnUrl === '') {
                this.router.navigate([RouteTypes.DOCUMENTS]);
              } else {
                this.router.navigate([this.returnUrl]);
              }
              this.authService.setSubject(result.data);
            } else {
              this.notificationService.error(appConstant.USER_NOT_CONFIGURED);
              this.isLoading = false;
              this.isAccessCodeSent = false;
              this.loginForm.reset();
              this.emailField.nativeElement.focus();
            }
          } else if (result.message === ResponseMessageType.ERROR) {
            this.loginModel.accessCode = '';
            this.isFormSubmitted = false;
            this.isLoading = false;
            if (result.httpStatus === 403) {
              this.resetLoginForm();
            }
          }
        } else {
          this.notificationService.error('Something went wrong....');
          this.loginModel.accessCode = '';
          this.isFormSubmitted = false;
          this.isLoading = false;
        }
      },
      (error) => {
        this.isLoading = false;
        this.loginForm.reset();
        this.emailField.nativeElement.focus();
      }
    );
  }
}
