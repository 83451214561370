export class WorkSpaceSecurityRequestModel {
  libraryGuid: string;
  number: number;
  version?: number;
  constructor() {}
}

export class MatterFolderSecurityRequestModel {
  libraryGuid: string;
  itemNumber: number;
  constructor() {}
}
