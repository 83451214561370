import { environment } from 'src/environments/environment';
import  config from '../assets/config.json';

export let baseURL: string = environment.apiBaseURL;

export let configJSON = {
  validateAccessCode: baseURL + config.validateAccessCode,
  getAccessCode: baseURL + config.getAccessCode,
  refreshToken: baseURL + config.refreshToken,
  getUserConnection: baseURL + config.getUserConnection,
  getDocumentData: baseURL + config.getDocumentData,
  getLibrariesData: baseURL + config.getLibrariesData,
  getProperties: baseURL + config.getProperties,
  getEmailProperties: baseURL + config.getEmailProperties,
  getMatter: baseURL + config.getMatter,
  getUser: baseURL + config.getUser,
  getMatterProperties: baseURL + config.getMatterProperties,
  getEmailData: baseURL + config.getEmailData,
  getFolderList: baseURL + config.getFolderList,
  getFolderProperty: baseURL + config.getFolderProperty,
  getAllNestedFolder: baseURL + config.getAllNestedFolder,
  getParentListInfo: baseURL + config.getParentListInfo,
  getWorkspaceList: baseURL + config.getWorkSpaceInfo,
  getWorkspaceSecurityDataUrl: baseURL + config.getWorkspaceSecurityDataUrl,
  getDownloadDocument: baseURL + config.getDownloadDocument,
  getMatterFolderSecurityDataUrl: baseURL + config.getMatterFolderSecurityDataUrl,
  getMatterDocument: baseURL + config.getMatterDocument,
  getMatterEmails: baseURL + config.getMatterEmails,
  getMatterAllFiles: baseURL + config.getMatterAllFiles,
  getLibraryByConnection: baseURL + config.getLibraryByConnection,
  getLogoutUser: baseURL + config.getLogoutUser
};

export let isProduction = environment.production;
