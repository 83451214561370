import { Component, OnInit } from '@angular/core';
import  appConstant from '../../assets/app-text.json';

@Component({
  selector: 'haven-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  pageNotFoundHeaderText: string;
  pageNotFoundErrorMessage: string;
  pageNOtFoundErrorCode: string;
  constructor() { }

  ngOnInit() {
  this.pageNotFoundHeaderText =  appConstant.PAGE_NOT_FOUND_HEADER;
  this.pageNotFoundErrorMessage = appConstant.PAGE_NOT_FOUND_MESSAGE;
  this.pageNOtFoundErrorCode = appConstant.PAGE_NOT_FOUND_ERROR_CODE;
  }

}
