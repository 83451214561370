import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';


@Pipe({
    name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

    constructor() { }

    transform(value: any) {
        if (!value) { return ''; }
        const locale = window.navigator.language || 'en';
        const localeData = moment.localeData(locale);
        const format = localeData.longDateFormat('L') + ' ' + localeData.longDateFormat('LTS');
        return moment(value).format(format);
    }
}
