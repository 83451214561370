import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from 'src/app/services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const requestedPageState: string = state.url.split('/')[1];

    if (
        this.authenticationService.getClientId()  &&
        this.authenticationService.getIManageUserId()  &&
      this.authenticationService.getUserId()
    ) {
      this.authenticationService.setSubject(true);
      if (requestedPageState === 'pagenotfound') {
        return true;
      }
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: state.url,
      },
    });
    return false;
  }
}
