<div
  id="page-content-wrapper"
  [ngStyle]="{ 'margin-right': isShowPropertyTab ? '350px' : '0px' }"
>
  <div class="container-fluid" *ngIf="headerText != ''">
    <div class="mobile-hide tablet-hide bg-white">
      <div class="data-module-heading pl-15 pt-2 pb-2">
        <div class="row">
          <div class="col-lg-12">{{ headerText }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-white">
    <div class="mobile-hide tablet-hide bg-white">
      <div>
        <div class="row select-grid m-0 p-0">
          <div class="col-sm-6 col-lg-6">
            <span *ngIf="tabConfiguration.isSelectable" class="select-all">
              <input
                class="select-checkbox"
                id="select_all"
                type="checkbox"
                [(ngModel)]="isCheckedAll"
                (change)="getSelectedValue($event, 'All')"
              />
              <label for="select_all" class="select_all"> Select All</label>
            </span>
          </div>
          <div class="col-sm-6 col-lg-6 text-right">
            <button
              *ngIf="selectedElement.length > 1"
              (click)="downloadSelectedItem()"
              class="btn btn-download"
              type="button"
            >
              <label class="download-label">Download</label>
              <span
                ><img width="18" src="assets/images/DownloadButton.svg"
              /></span>
            </button>
            <span class="input-label">Sort by: </span>
            <div class="dropdown display-inline">
              <select class="select-dropdown" (change)="onSortedBy($event)">
                <option
                  class="dropdown-item"
                  *ngFor="let option of sortBy"
                  value="{{ option.value }}"
                  [selected]="option.selected"
                  >{{ option.displayName }}</option
                >
              </select>
            </div>
            <a class="asc-arrow" (click)="getOrderBy($event)">
              <span
                ><img
                  src="assets/images/sort-arrow.svg"
                  [ngClass]="!orderByValue ? 'spin' : 'spin-0'"
              /></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="data-grid" #grid *ngIf="data && data.length">
      <haven-loader [loaderEnabled]="isLoading"></haven-loader>
      <div class="none">
        <ul id="data-set-row" class="ul-grid">
          <ng-container *ngIf="isAllowGrouping">
            <ng-container *ngFor="let item of data; let i = index">
              <div *ngIf="i === todaylistIndex">Today</div>
              <li
                *ngIf="item.isToday"
                [ngClass]="
                  item.isChecked
                    ? 'activeGrid'
                    : i == selectedTab && !item.isChecked
                    ? 'seletedGrid'
                    : ''
                "
                [id]="item.description.number"
              >
                <div class="row" (click)="updateProperties(item, i)">
                  <div class="d-flex doc-info">
                    <div
                      *ngIf="tabConfiguration.isSelectable"
                      class="checkbox-input"
                    >
                      <input
                        class="width-13"
                        type="checkbox"
                        aria-label="Checkbox for following text input"
                        [(ngModel)]="item.isChecked"
                        (click)="
                          getSelectedValue($event, '', i);
                          $event.stopPropagation()
                        "
                      />
                    </div>

                    <div class="icon-img">
                      <img
                        src="assets/images/{{
                          getFileExtention(item.description.extension)
                        }}.svg"
                      />
                    </div>
                    <div class="doc-info-col-3">
                      <div class="col-lg-12 p-0">
                        <div class="item-details">
                          <span
                            class="doc-file-name {{
                              itemConfiguration[item.itemType]['isclickable']
                                ? 'matters-grid'
                                : ''
                            }} "
                            (click)="
                              itemConfiguration[item.itemType]['isclickable']
                                ? onDocumentClick(item)
                                : false;
                              $event.stopPropagation()
                            "
                            >{{ item.name }}</span
                          >
                        </div>

                        <ng-container
                          [ngComponentOutlet]="subComponent[item.itemType]"
                          [ndcDynamicInputs]="item"
                        ></ng-container>
                      </div>
                    </div>
                  </div>
                  <div class="mobile-hide tablet-hide context-menu-desktop">
                    <div class="dropdown">
                      <button
                        *ngIf="
                          itemConfiguration[item.itemType][
                            'isPropertyTabRequired'
                          ]
                        "
                        class="btn input-label"
                        type="button"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        (click)="
                          openPopupMenu(item.description.number); 
                        "
                      >
                        <span class="ellipsis-icon"
                          ><i class="fa fa-ellipsis-v"></i
                        ></span>
                      </button>
                      <button
                        *ngIf="
                          !itemConfiguration[item.itemType][
                            'isPropertyTabRequired'
                          ]
                        "
                        class="dropdown-item btn-context-menu"
                        type="button"
                        (click)="
                          openPropertiesNav(item); 
                        "
                      >
                        <span
                          ><img width="18" src="assets/images/menuInfo.svg"
                        /></span>
                      </button>
                      <div
                      id="propertiesPopupMenu{{item.description.number}}"
                        class="dropdown-menu dropdown-menu-right input-label"
                        aria-labelledby="dropdownMenu2"
                        x-placement="bottom-end"
                      >
                        <button
                          class="dropdown-item btn-context-menu"
                          type="button"
                          [disabled] = "!isDocumentDownloadAllow"
                          (click) = "onDownloadEmailDocument(item, 'Direct')"
                        >
                          <!-- <a
                            [href]="item.docURLPath"
                            target="_blank"
                            download
                            class="font-grey"
                          > -->
                            <span
                              ><img width="18" src="assets/images/Download.svg"
                            /></span>
                            Download
                          <!-- </a> -->
                        </button>
                        <button
                          *ngIf="
                            itemConfiguration[item.itemType]['isReceiveByEmail']
                          "
                          href="javascript:void(0)"
                          class="dropdown-item btn-context-menu"
                          type="button"
                          [disabled] = "!isEmailDocumentAllowed"
                          (click) = "onDownloadEmailDocument(item, 'Email')"
                        >
                          <span
                            ><img
                              width="18"
                              src="assets/images/email-kebobmenu.svg"
                          /></span>
                          Receive By Email
                        </button>
                        <button
                          class="dropdown-item btn-context-menu"
                          type="button"
                          *ngIf="!isShowPropertyTab"
                          (click)="openPropertiesNav(item)"
                        >
                          <span
                            ><img width="18" src="assets/images/menuInfo.svg"
                          /></span>

                          Properties
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="desktop-hide download-item">
                    <div class="btn-wrap">
                      <button class="btn input-label" type="button" (click)= "onDownloadEmailDocument(item, 'Direct')">
                        <span
                          ><img width="30" src="assets/images/download-icn.svg"
                        /></span>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ng-container>
        </ul>
      </div>
      <div class="none">
        <ul id="data-set-row" class="ul-grid-0">
          <ng-container *ngFor="let item of data; let i = index">
            <div *ngIf="isAllowGrouping && i === olderListIndex">Older</div>
            <li
              *ngIf="isAllowGrouping ? !item.isToday : true"
              [ngClass]="
                item.isChecked
                  ? 'activeGrid'
                  : i == selectedTab && !item.isChecked
                  ? 'seletedGrid'
                  : ''
              "
              [id]="item.description.number"
            >
              <div class="row" (click)="updateProperties(item, i)">
                <div class="d-flex doc-info">
                  <div
                    *ngIf="tabConfiguration.isSelectable"
                    class="checkbox-input"
                  >
                    <input
                      class="width-13"
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      [(ngModel)]="item.isChecked"
                      (click)="
                        getSelectedValue($event, '', i);
                        $event.stopPropagation()
                      "
                    />
                  </div>

                  <div class="icon-img">
                    <img
                      src="assets/images/{{
                        getFileExtention(item.description.extension)
                      }}.svg"
                    />
                  </div>

                  <div class="doc-info-col-3">
                    <div class="col-lg-12 p-0">
                      <div class="item-details">
                        <span
                          class="doc-file-name {{
                            itemConfiguration[item.itemType]['isclickable']
                              ? 'matters-grid'
                              : ''
                          }} "
                          (click)="
                            itemConfiguration[item.itemType]['isclickable']
                              ? onDocumentClick(item)
                              : false;
                            $event.stopPropagation()
                          "
                          >{{ item.name }}</span
                        >
                      </div>
                      <ng-container
                        [ngComponentOutlet]="subComponent[item.itemType]"
                        [ndcDynamicInputs]="item"
                      ></ng-container>
                    </div>
                  </div>
                </div>
                <div class="mobile-hide tablet-hide context-menu-desktop">
                  <div class="dropdown">
                    <button
                      *ngIf="
                        itemConfiguration[item.itemType][
                          'isPropertyTabRequired'
                        ]
                      "
                      class="btn input-label"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      (click)="openPopupMenu(item.description.number)"
                    >
                      <span class="ellipsis-icon"
                        ><i class="fa fa-ellipsis-v"></i
                      ></span>
                    </button>
                    <button
                      *ngIf="
                        !itemConfiguration[item.itemType][
                          'isPropertyTabRequired'
                        ]
                      "
                      class="matter-properties dropdown-item btn-context-menu"
                      type="button"
                      (click)="
                        openPropertiesNav(item);
                      "
                    >
                      <span
                        ><img width="18" src="assets/images/menuInfo.svg"
                      /></span>
                    </button>
                    <div
                      id="propertiesPopupMenu{{item.description.number}}"
                      class="dropdown-menu dropdown-menu-right input-label"
                      aria-labelledby="dropdownMenu2"
                      x-placement="bottom-end"
                    >
                      <button
                        class="dropdown-item btn-context-menu"
                        type="button"
                        [disabled] = "!isDocumentDownloadAllow"
                        (click) = "onDownloadEmailDocument(item, 'Direct')"
                      >
                        <!-- <a
                          [href]="item.docURLPath"
                          target="_blank"
                          download
                          class="font-grey"
                        > -->
                          <span
                            ><img width="18" src="assets/images/Download.svg"
                          /></span>
                          Download
                        <!-- </a> -->
                      </button>
                      <button
                        *ngIf="
                          itemConfiguration[item.itemType]['isReceiveByEmail']
                        "
                        href="javascript:void(0)"
                        class="dropdown-item btn-context-menu"
                        type="button"
                        [disabled] = "!isEmailDocumentAllowed"
                        (click) = "onDownloadEmailDocument(item, 'Email')"
                      >
                        <span
                          ><img
                            width="18"
                            src="assets/images/email-kebobmenu.svg"
                        /></span>
                        Receive By Email
                      </button>
                      <button
                        class="dropdown-item btn-context-menu"
                        type="button"
                        *ngIf="!isShowPropertyTab"
                        (click)="openPropertiesNav(item)"
                      >
                        <span
                          ><img width="18" src="assets/images/menuInfo.svg"
                        /></span>

                        Properties
                      </button>
                    </div>
                  </div>
                </div>
                <div class="desktop-hide download-item">
                  <div class="btn-wrap">
                    <button class="btn input-label" type="button" (click)= "onDownloadEmailDocument(item, 'Direct')">
                      <span
                        ><img width="30" src="assets/images/download-icn.svg"
                      /></span>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div class="container-fluid bg-white" *ngIf="data && !data.length && !isLoading">
    <div class="row">
      <div class="col-md-12 not-data-foud">
        {{ dataLoadingText }}
      </div>
    </div>
  </div>
  <div class="container-fluid bg-white" *ngIf="data && !data.length && isLoading">
    <div class="row">
      <haven-loader [loaderEnabled]="isLoading"></haven-loader>
      <div class="col-md-12 not-data-foud"></div>
    </div>
  </div>
</div>
<div
  *ngIf="isShowPropertyTab"
  class="properties-nav"
  id="properties-wrapper"
  [ngStyle]="{ width: isShowPropertyTab ? '350px' : '0px' }"
>
  <div class="hanging-btns">
    <button
      href="javascript:void(0)"
      class="btn show-btn"
      type="button"
      (click)="closePropertiesNav()"
    >
      <span>
        <img width="18" src="assets/images/mainProfileburron.svg" />
      </span>
    </button>
  </div>
  <ng-container
    [ngComponentOutlet]="propertyWrapper"
    [ndcDynamicInputs]="propertyDiscription"
  ></ng-container>
</div>
<!--paggination-->
<div
  *ngIf="data && data.length"
  class="grid-bottom"
  [ngStyle]="{ 'padding-right': isShowPropertyTab ? '350px' : '0px' }"
>
  <div class="row">
    <div class="col-sm-6 col-lg-6 pt-1 pl-3 displayabove">
      <span>1 to {{ data.length }} of {{ getTotal.totalCount }}</span>
      <span class="load-more" *ngIf="!getTotal.isEOF"><button class="load-more-btn" (click)="loadMoreData($event)">
        Load More...
      </button></span>
    </div>
    <div class="col-sm-2 col-lg-2 p-1 " >
      
    </div>
    <div class="col-sm-4 col-lg-4 p-1"></div>
    
  </div>
</div>

