export enum RouteEventTypeEnum {
  DOCUMENTS = 'documents',
  EMAILS = 'emails',
  MATTERS = 'matters',
  CLIENTS = 'clients',
  FOLDERS = 'folders',
  EMAIL = 'Email',
}
export enum RouteTypes {
  DOCUMENTS = '/dashboard/documents',
  EMAILS = '/dashboard/emails',
  MATTERS = '/dashboard/matters',
}
