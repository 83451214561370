export class WorkSpaceSecurityResponseModel {
  count: number;
  defaultSecurity: string;
  itemSecurities: ItemSecurity[];
  constructor() {
    this.itemSecurities = [];
  }
}
export class ItemSecurity {
  accessLevel: number;
  name: string;
  type: string;
  userGrpFullName: string;
  userGrpId: string;
}
