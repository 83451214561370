import { Component, OnInit } from '@angular/core';

import {
  trigger,
  transition,
  animate,
  style,
  state,
} from '@angular/animations';
import  modalData from '../../../assets/modal-text.json';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
// import { ConfirmationDialogService } from 'src/app/services/index.js';
@Component({
  selector: 'haven-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      // the 'in' style determines the 'resting' state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ transform: 'translateY(-50%)', opacity: 0 }),
        animate(100),
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(
        ':leave',
        animate(200, style({ transform: 'translateY(0%)', opacity: 0 }))
      ),
    ]),
  ],
})
export class ConfirmationDialogComponent implements OnInit {
  // message can be any type of JSON Object  depends on Component to Component
  message: any;
  statusCode: string;
  public sVal = false;
  public modalConstant = {};
  public appConstant = {};
  constructor(private confirmDialogService: ConfirmationDialogService) {}

  ngOnInit() {
    // this function waits for a message from alert service, it gets
    // triggered when we call this from any other component
    this.confirmDialogService.getMessage().subscribe((message) => {
      this.message = message;
    });
    // TO-DO impact analysis pending
    // this.modalConstant = modalData['default'];
    this.modalConstant = modalData;
  }
  close() {
    // this.confirmDialogService.clearMessage();
  }
}
