import { DatabaseList } from './document';

export class MatterRequest {
  mattersFilter: MatterFilter;
  search: IMatterSearch;
  listType: string;
  orderBy: string;
  orderByAsc: number;
  totalNo: number;
  idCursor?: string;
  sortCursor?: string;
}

export class MatterFilter {
  matterDatabase: DatabaseList[];
}

// export class DatabaseList {
//   libraryId?: number;
// }

// TODO: Search Class will modify once search story in place
export class IMatterSearch {}

export class MatterResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: MatterList;
  dataEnumerable: any[] = [];
  errors: any[] = [];
}

export class MatterList {
  isEOF: boolean;
  totalCount: number;
  searchParam: string;
  filterParams: string;
  matterList: MatterDataList[];
}

export class MatterDataList {
  docName: string;
  isToday: boolean;
  itemType: string;
  description: IMatterDescription;
  matterProperties: MatterProperties;
}

export class IMatterDescription {
  matterWorkId: number;
  matterGuid: string;
  clientMatterCustom1: string;
  matterCustom2: string;
  docExtension: string;
  docId: string;
  libraryGuid: string;
  editDate: string;
  createDate: string;
}

export class MatterProperties {
  matterName: string;
  owner: string;
  description: string;
  createDate: string;
  client: string;
  matter: string;
}
export class MatterStorageModel {
  matterBreadCrums: string[] = [];
  matterBreadCrumIds: number[] = [];
  selectedMatterId: any;
  requestModel: MatterRequest;
  selectedMatter: any = {};
  selectedMatterIndex: number;
  isPropertyTabShown: boolean;
  constructor() {
    this.selectedMatterIndex = -1;
    this.isPropertyTabShown = false;
  }
}

export class ISelectedMatter {
  elementId: string;
  libraryId: string;
}

export class IMatterInfoProperty {
  version: number;
  libraryId: string;
  docNum: string;
  documentId: string;
}

