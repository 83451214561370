export class DocumentRequest {
  docFilter: DocFilter;
  search: Search;
  listType: string;
  orderBy: string;
  orderByAsc: number;
  totalNo: number;
  idCursor?: string;
  sortCursor?: string;
  containerWorkId?: number;
  excludeFolders?: boolean;
}

export class DocFilter {
  documentActivies: string;
  documentFileType: string;
  documentAuthorOperator: any;
  documentDatabase: DatabaseList[];
}

export class DatabaseList {
  libraryGuid?: string;
}

// TODO: Search Class will modify once search story in place
export class Search {}

export class DocumentResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: DocumentList;
  dataEnumerable: any[] = [];
  errors: any[] = [];
}

export class DocumentList {
  isEOF: boolean;
  totalCount: number;
  searchParam: string;
  filterParams: string;
  docList?: DocumentDataList[];
  emailDocumentList?: DocumentDataList[];
}

export class DocumentDataList {
  name: string;
  isToday: boolean;
  itemType: string;
  urlPath: string;
  description: Description;
  documentProperties: DocumentProperties;
  documentVersions: DocumentVersions;
}

export class Description {
  latestVersion: number;
  number: number;
  documentGuid: string;
  docExtension: string;
  libraryGuid: string;
  editDate: string;
  createDate: string;
  size: number;
  defaultSecurity: string;
}

export class DocumentProperties {
  author: string;
  type: string;
  className: string;
  operator: string;
  createDate: string;
  client: string;
  matter: string;
  workspace?: string;
}

export class DocumentVersions {
  docVersionName: string;
  docVersionURLPath: string;
  docVersionOrder: number;
  docVersion: number;
  lastUser: string;
  dateModified: string;
}
export class DocumentStorageModel {
  requestModel: DocumentRequest;
  selectedDocument: any = {};
  selectedDocumentIndex: number;
  isPropertyTabShown: boolean;
  constructor() {
    this.selectedDocumentIndex = -1;
    this.isPropertyTabShown = false;
  }
}

export class ITotal {
  isEOF: boolean;
  totalCount: number;
}

export class IDocInfoProperty {
  version: number;
  libraryGuid: string;
  docNum: string;
  documentGuid: string;
}
