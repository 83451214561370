import { Injectable } from '@angular/core';
import  searchConfig from '../../assets/searchConfig.json';
import { SearchDisplayModel } from '../common/models/searchDisplayModel';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public searchCalledSubject = new Subject<any>();
  private searchServiceSubject = new BehaviorSubject<any>({});
  constructor() {}
  getAllSearchItems(): SearchDisplayModel[] {
    const items: SearchDisplayModel[] = [];
    const searchCriterias = searchConfig.SearchCriterias;
    searchCriterias.forEach((searchCriteria: SearchDisplayModel) => {
      items.push(searchCriteria);
    });
    return items;
  }
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  setSearchSubject(data: any) {
    this.searchServiceSubject.next(data);
  }
  clearSearchSubject() {
    this.searchServiceSubject.next({});
  }
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  getSearchSubject(): Observable<any> {
    return this.searchServiceSubject.asObservable();
  }
  searchCalled() {
    this.searchCalledSubject.next({ isSearchCalled: true });
  }
  getSearchCallMessage(): Observable<any> {
    return this.searchCalledSubject.asObservable();
  }
  clearSearchCallMessage() {
    this.searchCalledSubject.next();
  }
}
