import { Component, OnInit, DoCheck, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services';
import { TokenService } from './services/token.service';

@Component({
  selector: 'haven-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'HAVEN-ClientPortal';
  isHeaderVisible = false;
  constructor(private router: Router, private authService: AuthService, private tokenService: TokenService) {}
  ngOnInit() {
    this.authService.getSubject().subscribe((result) => {
      if (!!result) {
        this.isHeaderVisible = true;
      } else {
        this.isHeaderVisible = false;
      }
    });
  }
  loggedOut() {
    this.isHeaderVisible = false;
  }
  idleTimeExpired() {
    this.authService.logout();
  }
  @HostListener('click', ['$event'])
  customEventHandler(event: any) {
    {
      if (
        this.router.url.indexOf('login') === -1
      ) {
        this.tokenService.checkTokenValidity();
      }
    }
  }
}
