import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SearchCategoryType } from '../common/enum';

@Directive({
  selector: 'input[havenOnlyAlphaNumeric]',
})
export class OnlyAlphaNumericDirective {
  @Input() selectionParam: string;
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event']) onInputChange(event) {
    let initalValue = this.el.nativeElement.value;
    let selectedTitle: string;
    switch (this.selectionParam) {
      case SearchCategoryType.TITLE:
        selectedTitle = initalValue.substr(0, 7);
        initalValue = initalValue.substr(7, initalValue.length - 1);
        break;
      case SearchCategoryType.AUTHOR:
        selectedTitle = initalValue.substr(0, 1);
        initalValue = initalValue.substr(1, initalValue.length - 1);
        break;
      case SearchCategoryType.NUMBER:
        selectedTitle = initalValue.substr(0, 1);
        initalValue = initalValue.substr(1, initalValue.length - 1);
        break;
      case SearchCategoryType.CLIENT:
        selectedTitle = initalValue.substr(0, 8);
        initalValue = initalValue.substr(8, initalValue.length - 1);
        break;
      case SearchCategoryType.MATTER:
        selectedTitle = initalValue.substr(0, 8);
        initalValue = initalValue.substr(8, initalValue.length - 1);
        break;
      case SearchCategoryType.SUBJECT:
        selectedTitle = initalValue.substr(0, 9);
        initalValue = initalValue.substr(9, initalValue.length - 1);
        break;
      default: {
        selectedTitle = '';
        break;
      }
    }


    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
