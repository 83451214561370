export class SearchModel {
  selectedSearchType: string;
  documentSearchModel: DocumentSearchModel;
  emailSearchModel: EmailSearchModel;
  matterSearchModel: MatterSearchModel;
  constructor() {
    this.documentSearchModel = new DocumentSearchModel();
    this.emailSearchModel = new EmailSearchModel();
    this.matterSearchModel = new MatterSearchModel();
  }
}
export class SearchCriteriaModel {
  imageName: string;
  displayText: string;
}
export class DocumentSearchModel {
  searchValue: string;
  searchCriteria: string;
  title: string;
  authorOperator: string;
  docNumber: number;
  client: string;
  matter: string;
  constructor() {
    this.searchCriteria = '';
    this.title = '';
    this.authorOperator = '';
    this.docNumber = null;
    this.client = '';
    this.matter = '';
    this.searchValue = '';
  }
}
export class EmailSearchModel {
  searchValue: string;
  searchCriteria: string;
  subject: string;
  docNumber: number;
  client: string;
  matter: string;
  constructor() {
    this.searchCriteria = '';
    this.subject = '';
    this.docNumber = null;
    this.client = '';
    this.matter = '';
    this.searchValue = '';
  }
}
export class MatterSearchModel {
  searchValue: string;
  searchCriteria: string;
  title: string;
  client: string;
  matter: string;
  constructor() {
    this.searchCriteria = '';
    this.title = '';
    this.client = '';
    this.matter = '';
    this.searchValue = '';
  }
}
