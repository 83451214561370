export class Menu {
  DisplayName: string;
  Url: string;
  Order: number;
  Id: number;
  Img: string;
  ActiveImg: string;
  Hide: boolean;

  constructor(
    displayName: string,
    url: string,
    order: number,
    id: number,
    img: string,
    activeImg: string,
    hide: boolean
  ) {
    this.DisplayName = displayName;
    this.Url = url;
    this.Order = order;
    this.Id = id;
    this.Img = img;
    this.ActiveImg = activeImg;
    this.Hide = hide;
  }
}
