import  constant from '../../dashboard/dashboard.constant.json';
export default class Utils {

  static getFileExtention(fileType) {
    // available file type sholud be added here
    const availableExtention = constant.availableExtention;
    if (fileType && availableExtention.hasOwnProperty(fileType.toLowerCase())) {
      return availableExtention[fileType.toLowerCase()];
    } else {
      return availableExtention.default;
    }
  }

  static encryptData(data: string): string {
    return window.btoa(data);
  }

  static decryptData(data: string): string {
    return atob(data);
  }

}
