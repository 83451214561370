<!-- Basic Modal -->
<div
  style="display: block !important;"
  *ngIf="message && message?.type == 'OK_AND_CANCEL_CONFIRMATION'"
  class="modal"
  tabindex="-1"
  role="dialog"
>
  <div
    style="min-width:434px"
    class="modal-dialog modal-dialog-centered"
    role="document"
  >
    <div class="modal-content" [@simpleFadeAnimation]>
      <div class="modal-header">
        <h4 class="modal-title">
          {{ message.title }}
        </h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="message.cancelFunc()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- -->
        <div>
          <div
            class="messageTitle"
            style="display:inline"
            [innerHTML]="message.messageTitle"
          ></div>
        </div>
        <div class="messageText" [innerHTML]="message.message"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-yes" (click)="message.okFunc()">
          {{ message.btnOkText }}
        </button>
        <button
          *ngIf="message.btnCancelText !== ''"
          type="button"
          class="btn btn-no"
          (click)="message.cancelFunc()"
        >
          {{ message.btnCancelText }}
        </button>
      </div>
    </div>
  </div>
</div>
