import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectedGridService {
  private selectedItemServiceSubject = new Subject<any>();
  constructor() {}
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  setSelectedItemSubject(data: any) {
    this.selectedItemServiceSubject.next(data);
  }
  clearSelectedItemSubject() {
    this.selectedItemServiceSubject.next();
  }
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  getSelectedItemSubject(): Observable<any> {
    return this.selectedItemServiceSubject.asObservable();
  }
}
