import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  NotificationService,
  AuthService,
  DashboardService,
} from 'src/app/services';
import { ResponseMessageType } from 'src/app/common/enum/responseMessageType';
import { SharedLeftpanelFilterService } from 'src/app/services/shared-services/shared-leftpanel-filter.service';
import { FilterTypeEnum, SelectTypeEnum } from 'src/app/common/enum/filterType';
import LeftPanelData from '../../dashboard/left-filter.constant.json';
import {
  Library,
  LibraryResponseModel,
} from 'src/app/common/models/librarylist';
import { LeftPanelLibrariesService } from 'src/app/services/leftPanel/left-panel-libraries.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ListTypeEnum } from 'src/app/common/enum';
import { ConnectionListService } from '../../services/shared-services/connection-list.service';
import { Subscription } from 'rxjs';
import { window } from 'rxjs/operators';

@Component({
  selector: 'haven-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss'],
})
export class LeftPanelComponent implements OnChanges, OnDestroy {
  @Input() filterData;
  @Input() listType;
  @Input() filterTitle;
  @Input() isLibraryLoad = false;
  @Input() isShowChildFilter = false;
  @Output() getSelectedFilter = new EventEmitter();
  arrySetSelectedLibrary = []; // Set Selected Library list
  userId: string;
  userName: string;
  isLoading: boolean;
  filterReq = {};
  localStorageData: any; // use any type for getting JSON from local storage
  filterStorage: any = {}; // use any type for Set value in local storage
  libraryGuid: string = null;
  isChild = false;
  subscription: Subscription;
  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private notificationService: NotificationService,
    private sharedLeftpanelFilterService: SharedLeftpanelFilterService,
    private leftPanelService: LeftPanelLibrariesService,
    private router: Router,
    private route: ActivatedRoute,
    private connectionListService: ConnectionListService
  ) {
      this.subscription =  this.connectionListService.getSubject().subscribe((data) => {
      this.getLibrariesDataService();
  });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.filterData) {
    this.userId = this.authService.getIManageUserId();
    this.userName = this.authService.getUserName();
    if (this.isLibraryLoad) {
      this.getLibrariesDataService();
    } else {
      // this.updateFilterForChildFilter
       // this.addReadOnlyLibraryData();
       this.resetChildFilter();
       this.sendfilterForDataLoad();
      }
    }
  }
  resetChildFilter() {
    const selectedFilter = this.filterData.filter((e) => e.tabName === 'folder');
    if (selectedFilter && selectedFilter.length > 0) {
      const selectredFilterView = selectedFilter[0].value.filter((e) => e.isSelected === true);
      this.updateFilterForChildFilter(selectedFilter[0], selectredFilterView[0]);
    } else {
      this.addReadOnlyLibraryData();
    }
  }

  updateFilterForChildFilter(filter, selectedFilterView) {
   if (filter.filterName === 'mattersViews') {

    if (selectedFilterView.value === 'folders') {
      this.filterData = this.filterData.filter((e) => e.tabName === 'folder');
      this.listType = ListTypeEnum.FOLDERS;
    } else {
      this.listType = this.getListTypeOfSelectedValue(selectedFilterView.value);
      const childFilter = LeftPanelData[selectedFilterView.value === 'all' ?  'documents' : selectedFilterView.value];
      this.filterData = [...this.filterData.filter((e) => e.tabName === 'folder'), ...childFilter];
      this.addReadOnlyLibraryData();
    }
  }
  }
   getListTypeOfSelectedValue(selectedValue: string) {
    let listType = ListTypeEnum.FOLDERS;
    switch (selectedValue) {
      case 'documents':
        listType = ListTypeEnum.MATTER_DOC;
        break;
      case 'email':
       listType = ListTypeEnum.MATTER_EMAIL;
       break;
      case 'all':
       listType = ListTypeEnum.MATTER_ALL;
       break;
    }
    return listType;
   }
  // Get I-manage library list With API Call
  getLibrariesDataService() {
    this.isLoading = true;
    this.leftPanelService
      .getConnectionLibrariesDataList(this.connectionListService.connectionGuid)
      .subscribe((result: LibraryResponseModel) => {
        this.isLoading = false;
        if (result && result.message === ResponseMessageType.SUCCESS) {
          this.getSelectedLibraryListFromLocalStorage(result.data);
          this.sendfilterForDataLoad();
        }
      }, (error) => {
      });
  }

  addReadOnlyLibraryData() {
    this.route.paramMap.subscribe((param: any) => {
      if (param.params.libraryGuid) {
        this.libraryGuid = param.params.libraryGuid;
    }
      if (param.params.folderGuid || param.params.matterGuid) {
      this.listType = ListTypeEnum.DOCUMENTS;
    }
      const readOnlyDatabaseValue = [{
      name: this.sharedLeftpanelFilterService.getLibraryName(this.listType, this.libraryGuid),
      isSelected: true,
      libraryGuid: this.libraryGuid
    }];
      this.getSelectedLibraryListFromLocalStorage(readOnlyDatabaseValue);
    });

  }
  // Get selected Library list from service
  getSelectedLibraryListFromLocalStorage(data: Library[]) {
    const localStoreDatabase = this.sharedLeftpanelFilterService.getSelectedLibraryList(this.listType);
    if (localStoreDatabase && localStoreDatabase.length > 0) {
    const selectedVal = localStoreDatabase[0].value;
    for (const dataObj of data) {
      for (const selectedObj of selectedVal) {
        if (dataObj.libraryGuid === selectedObj.libraryGuid) {
          dataObj.isSelected = selectedObj.isSelected;
        }
      }
    }
}
    this.filterData.forEach((element) => {
      if (element.name === FilterTypeEnum.DATABASE) {
        element.value = data;
      }
    });
  }
  // ============================END GET FILTER DATA============================

  onSelectFilter(filter, data, selectType) {
    this.updateFilterForChildFilter(filter, data);
    let invokeDataLoad = true;
    if (selectType === SelectTypeEnum.SINGLE) {
    filter.value.forEach(element => {
      if (element.isSelected && element.name === data.name) {
        invokeDataLoad = false;
      }
      element.isSelected = false;
    });
    data.isSelected = !data.isSelected;
    if (invokeDataLoad ) {
      this.sendfilterForDataLoad();
    }
  }
    if (selectType === SelectTypeEnum.MULTIPLE) {
    const selectedFilter = filter.value.filter((e) => {
        return e.isSelected;
    });
    if (selectedFilter.length > 1 || (selectedFilter.length === 1 &&
      selectedFilter[0].libraryGuid !== data.libraryGuid)) {
        data.isSelected = !data.isSelected;
        this.sendfilterForDataLoad();
    }
  }
  }

  sendfilterForDataLoad() {
    this.filterReq = {};
    this.filterData.forEach((element) => {
      if (element.selectType === SelectTypeEnum.SINGLE) {
        const selectValue = element.value.filter((e) => {
          return e.isSelected;
        });
        if (
          selectValue[0].name === 'All' &&
          element.filterName === 'documentFileType'
        ) {
          this.filterReq[element.filterName] = selectValue[0].value;
          }
        // Send userId for selection on 'Me' in Document Tab Author filter
        if (
          element.filterName === 'documentAuthorOperator' &&
          selectValue[0].value === 'me'
        ) {
          this.filterReq[element.filterName] = this.userId;
        } else {
          this.filterReq[element.filterName] = selectValue[0].value;
        }
      } else {
        const selectValue = element.value.filter((e) => {
          return e.isSelected;
        });
        if (element.name === FilterTypeEnum.DATABASE) {
          this.filterReq[element.filterName] = [];
          for (const selectObj of selectValue) {
            this.filterReq[element.filterName].push({
              libraryGuid: selectObj.libraryGuid,
            });
            this.arrySetSelectedLibrary.push(selectObj); // Add data from selected array
          }
        }
      }
    });
    if (this.listType === ListTypeEnum.DOCUMENTS ||
       this.listType === ListTypeEnum.EMAIL ||
       this.listType === ListTypeEnum.MATTERS) {
        this.sharedLeftpanelFilterService.storeSelectedFilterList(this.filterData, this.listType);
    }
    if (this.connectionListService.connectionURL) {
      this.getSelectedFilter.emit(this.filterReq);
    }
  }  // ================================END DATA PERSIST========================================
}
