import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../services';
import { Menu } from '../../common/models/menu';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
  RoutesRecognized,
} from '@angular/router';

@Component({
  selector: 'haven-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  menus;
  constructor(public menuService: MenuService) {}

  ngOnInit() {
    this.menus = this.menuService.getAllMenus();
  }
}
