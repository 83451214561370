<div class="search-div">
  <form
    #searchForm="ngForm"
    name="form"
    (ngSubmit)="onSearchSubmit()"
    novalidate
    [ngClass]="isNotSearch ? 'not-search' : ''"
  >
    <input
      type="text"
      placeholder="Please provide the search criteria"
      name="search"
      class="search-box col-10"
      autocomplete="off"
      #search="ngModel"
      [(ngModel)]="searchText"
      (click)="onSearchFocus()"
      (ngModelChange)="onSearchChange($event)"
      havenOnlyAlphaNumeric
      [selectionParam]="currentSearchCrieteria"
    />

    <a href="javascript:void(0);" (click)="clearSearchText()">
      <span class="search-closed-icon"
        ><img
          width="15"
          *ngIf="searchText"
          src="../../../assets//images/cross-icn-search.svg" /></span
    ></a>
    <a href="javascript:void(0);" (click)="onSearchSubmit()">
      <span class="search-box-logo"
        ><img src="../../../assets//images/search.svg" /></span
    ></a>
  </form>

  <div class="row p-0 m-0 serach-popup" *ngIf="isPopupToBeShown">
    <div class="col-lg-12 col-sm-12 p-0">
      <div class="search-auto">
        <ul class="nav nav-tabs">
          <li
            class="col"
            *ngFor="let searchType of searchtypes"
            [ngClass]="
              selectedSearchModel.selectedSearchType == searchType.displayText
                ? 'active'
                : ''
            "
          >
            <a
              data-toggle="tab"
              (click)="onSearchTypeSelected(searchType.displayText)"
              ><img
                width="24"
                height="25"
                src="assets/images/{{ searchType.imageName }}.svg"
              /><span class="{{ searchType.className }}">{{
                searchType.displayText
              }}</span></a
            >
          </li>
        </ul>
        <div class="tab-content">
          <div class="tab-pane in active">
            <ul class="searchItem-list">
              <li
                *ngFor="let search of searchCriteria"
                (click)="onSearchCategorySelected(search)"
              >
                <img
                  width="20"
                  height="25"
                  src="assets/images/{{ search.imageName }}.svg"
                />
                <span>{{ search.displayText }}:</span>
                <span *ngIf="search.displayText == 'Title'"
                  >{{
                    (selectedSearchModel.selectedSearchType == "Documents" &&
                      selectedSearchModel.documentSearchModel.title != "") ||
                    (selectedSearchModel.selectedSearchType == "Matters" &&
                      selectedSearchModel.matterSearchModel.title != "")
                      ? "*"
                      : ""
                  }}{{
                    selectedSearchModel.selectedSearchType == "Documents"
                      ? selectedSearchModel.documentSearchModel.title
                      : selectedSearchModel.selectedSearchType == "Matters"
                      ? selectedSearchModel.matterSearchModel.title
                      : ""
                  }}{{
                    (selectedSearchModel.selectedSearchType == "Documents" &&
                      selectedSearchModel.documentSearchModel.title != "") ||
                    (selectedSearchModel.selectedSearchType == "Matters" &&
                      selectedSearchModel.matterSearchModel.title != "")
                      ? "*"
                      : ""
                  }}</span
                >
                <span *ngIf="search.displayText == 'Author/Operator'"
                  >{{
                    selectedSearchModel.selectedSearchType == "Documents"
                      ? selectedSearchModel.documentSearchModel.authorOperator
                      : ""
                  }}{{
                    selectedSearchModel.selectedSearchType == "Documents" &&
                    selectedSearchModel.documentSearchModel.authorOperator != ""
                      ? "*"
                      : ""
                  }}</span
                >
                <span *ngIf="search.displayText == 'Number'">{{
                  selectedSearchModel.selectedSearchType == "Documents"
                    ? selectedSearchModel.documentSearchModel.docNumber
                    : selectedSearchModel.selectedSearchType == "Emails"
                    ? selectedSearchModel.emailSearchModel.docNumber
                    : ""
                }}</span>
                <span *ngIf="search.displayText == 'Subject'"
                  >{{
                    selectedSearchModel.selectedSearchType == "Emails" &&
                    selectedSearchModel.emailSearchModel.subject != ""
                      ? "*"
                      : ""
                  }}{{
                    selectedSearchModel.selectedSearchType == "Emails"
                      ? selectedSearchModel.emailSearchModel.subject
                      : ""
                  }}{{
                    selectedSearchModel.selectedSearchType == "Emails" &&
                    selectedSearchModel.emailSearchModel.subject != ""
                      ? "*"
                      : ""
                  }}</span
                >
                <span *ngIf="search.displayText == 'Client'"
                  >{{
                    selectedSearchModel.selectedSearchType == "Documents"
                      ? selectedSearchModel.documentSearchModel.client
                      : selectedSearchModel.selectedSearchType == "Emails"
                      ? selectedSearchModel.emailSearchModel.client
                      : selectedSearchModel.selectedSearchType == "Matters"
                      ? selectedSearchModel.matterSearchModel.client
                      : ""
                  }}{{
                    (selectedSearchModel.selectedSearchType == "Documents" &&
                      selectedSearchModel.documentSearchModel.client != "") ||
                      (selectedSearchModel.selectedSearchType == "Emails" &&
                      selectedSearchModel.emailSearchModel.client != "") ||
                    (selectedSearchModel.selectedSearchType == "Matters" &&
                      selectedSearchModel.matterSearchModel.client != "")
                      ? "*"
                      : ""
                  }}</span
                >
                <span *ngIf="search.displayText == 'Matter'">{{
                  selectedSearchModel.selectedSearchType == "Documents"
                    ? selectedSearchModel.documentSearchModel.matter
                    : selectedSearchModel.selectedSearchType == "Emails"
                    ? selectedSearchModel.emailSearchModel.matter
                    : selectedSearchModel.selectedSearchType == "Matters"
                    ? selectedSearchModel.matterSearchModel.matter
                    : ""
                }}{{
                    (selectedSearchModel.selectedSearchType == "Documents" &&
                      selectedSearchModel.documentSearchModel.matter != "") ||
                      (selectedSearchModel.selectedSearchType == "Emails" &&
                      selectedSearchModel.emailSearchModel.matter != "") ||
                    (selectedSearchModel.selectedSearchType == "Matters" &&
                      selectedSearchModel.matterSearchModel.matter != "")
                      ? "*"
                      : ""
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
