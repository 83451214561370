import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService, NotificationService } from 'src/app/services';
import  appConfig from '../../../assets/app-text.json';
import { catchError, tap } from 'rxjs/operators';
import { ResponseMessageType } from 'src/app/common/enum';
import { ConnectionListService } from '../../services/shared-services/connection-list.service';
import  configUrl from '../../../assets/config.json';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private connectionListService: ConnectionListService
  ) {}

  apiByPassExceptionList: string[] = [configUrl.getDocumentData ,
                                      configUrl.getProperties ,
                                      configUrl.getWorkSpaceInfo ,
                                      configUrl.getEmailData ,
                                      configUrl.getEmailData ,
                                      configUrl.getMatter ,
                                      configUrl.getFolderList ,
                                      configUrl.getFolderProperty ,
                                      configUrl.getAllNestedFolder ,
                                      configUrl.getMatterDocument ,
                                      configUrl.getMatterEmails ,
                                      configUrl.getMatterAllFiles];

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const userId = this.authService.getUserId();
    const iManageUserId = this.authService.getIManageUserId();
    const clientId = this.authService.getClientId();
    const userEmail = this.authService.getUserEmail();
    const userName = this.authService.getUserName();
    const userRoleName = this.authService.getUserRole();
    if (userId) {
      request = request.clone({
        setHeaders: {
          IManageUserId: `${iManageUserId}`,
          UserGuid: `${userId}`,
          ClientGuid: `${clientId}`,
          'Content-Type': 'application/json',
          EmailAddress: `${userEmail}`,
          UserFullName: `${userName}`,
          UserRoleName: `${userRoleName}`
        },
      });
    }
    const urlObject = new URL(request.url);
    const urlObjectWithParam = new URL(request.urlWithParams);
    const updatedURL = {
      urlWithParams: this.connectionListService.getUpdatedRequestURL(urlObjectWithParam),
      url: this.connectionListService.getUpdatedRequestURL(urlObject)
    };

    request = Object.assign(request, updatedURL);
    if (this.apiByPassExceptionList.includes(urlObject.pathname)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      tap((result: any) => {
        if (
          result &&
          result.body &&
          result.body.message === ResponseMessageType.ERROR
        ) {
          const userMessage = result.body.errors[0].userMessage;
          this.showErrorMessage(new URL(request.url), userMessage);
          if (result.body.httpStatus === 401) {
            this.authService.logout();
          }
        }
      }),
      catchError((err) => {
        if (err.status === 401) {
          this.notificationService.error(appConfig.USR_LGN_DNY_MSG);
          this.authService.logout();
          return throwError(err);
        }
        this.notificationService.error(appConfig.SERVER_DOWN_MESSAGE);
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

  showErrorMessage(requestURL: URL, userMessage: string) {
  const ignoreMessageAPI: string[] = [];
  if (!ignoreMessageAPI.includes(requestURL.pathname)) {
    this.notificationService.error(userMessage);
  }
  }
}


