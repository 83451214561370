import { Injectable } from '@angular/core';
import { baseURL, configJSON } from '../../appConfig';
import { HttpClient } from '@angular/common/http';
import  config from '../../../assets/config.json';
import { ConnectionListModel, ConnectionList } from '../../common/models';
import { Observable, Subject } from 'rxjs';
import { ResponseMessageType } from '../../common/enum';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectionListService {
  public connectionURL: URL = null;
  public connectionGuid = '';
  connectionServiceSubject = new Subject<any>();
  public userConnectionList: ConnectionList[];
  public isConnectionDisable = false;
  constructor(private http: HttpClient, private authService: AuthService) {
  }
  getUpdatedRequestURL(requestURL: URL) {
    const requestURLPathname = requestURL.pathname.toLocaleLowerCase();
    const objBaseURL = new URL(baseURL);
    let evaluatedURL = objBaseURL.origin;

    if (
      (requestURLPathname.includes(config.getAccessCode.toLocaleLowerCase())) ||
      (requestURLPathname.includes(config.validateAccessCode.toLocaleLowerCase()) ||
      (requestURLPathname.includes(config.refreshToken.toLocaleLowerCase())) ||
      (requestURLPathname.includes(config.getUserConnection.toLocaleLowerCase()))
      )
    ) {
      evaluatedURL = objBaseURL.origin;
    } else {
     evaluatedURL = this.connectionURL ?  this.connectionURL.origin : evaluatedURL;

    }
    return evaluatedURL + requestURL.pathname + requestURL.search;
  }

  public setConnectionList() {
      this.connectionURL = null;
      this.http.post<ConnectionListModel>(
      configJSON.getUserConnection,
      {},
      {withCredentials: true}
    ).subscribe((result: ConnectionListModel) => {
      if ((result && result.message === ResponseMessageType.SUCCESS) && (result.data && result.data.length)) {
         this.connectionURL = new URL(result.data[0].dataCenterUrl);
         this.connectionGuid = result.data[0].connectionGuid;
         this.userConnectionList = result.data;
      } else {
        // Logout the user if connection is not available
        this.authService.logout();
      }
     }, (error) => {
       // Logout the user if any error occured while getting the connections
       this.authService.logout();
     });
  }

  setSubject(connectionGuid: string) {
    this.connectionGuid = connectionGuid;
    this.userConnectionList.map((connection) => {
    if (connection.connectionGuid === connectionGuid) {
      this.connectionURL = new URL(connection.dataCenterUrl);
    }
   });
    this.connectionServiceSubject.next(connectionGuid);
  }

  getSubject(): Observable<any> {
    return this.connectionServiceSubject.asObservable();
  }
}
