import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import  appConstant from '../../../assets/app-text.json';
import { Subscription } from 'rxjs';
import { SelectedGridService } from 'src/app/services';
import Utils from 'src/app/common/utils/app.utils';
const moment = require('moment');

@Component({
  selector: 'haven-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss'],
})
export class CustomGridComponent implements OnChanges, OnInit, OnDestroy {
  private grid: ElementRef;
  private propertiesPopupMenu: ElementRef;
  @ViewChild('grid') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.grid = content;
      if (this.propertyDiscription && this.propertyDiscription.description) {
        this.scrollSelectedItemToTop(this.propertyDiscription.description.number);
      }
    }
  }
  @Input() data = [];
  @Input() subComponent;
  /**
   * As it is custom component and the tabconfiguration
   * for the different tab is different Hence using any
   * as a type of tabconfiguration variable
   */
  @Input() tabConfiguration: any = {};
  @Input() itemConfiguration;
  @Input() isLoading = false;
  @Input() propertyComponent;
  @Input() getTotal;
  @Input() headerText;
  @Input() isDocumentDownloadAllow?: boolean;
  @Input() isEmailDocumentAllowed?: boolean;
  @Output() tabDiscriptions = new EventEmitter();
  @Output() sortedBy = new EventEmitter();
  @Output() downloadSelectedItems = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() gridItemClicked = new EventEmitter();
  @Output() gridItemSelected = new EventEmitter();
  @Output() propertyPanelClosed = new EventEmitter();
  @Output() propertyPanelOpened = new EventEmitter();
  @Output() downloadEmailDocument = new EventEmitter();
  @Input() updateRequired?: boolean;
  isShowPropertyTab = false;
  updateSortBy;
  sortBy = [];
  orderByValue: number;
  groupingOn: Array<string>;
  todaylistIndex: number;
  olderListIndex: number;
  /**
   * use any as property discription for the different tab is different
   * for the different tab Hence using the type any for property
   */
  propertyDiscription: any;
  isCheckedAll = false;
  selectedElement = [];
  selectedTab = -1;
  sortByValue: string;
  isAllowGrouping: boolean;
  propertyWrapper: any;
  dataLoadingText: string;
  gridSubscription: Subscription;
  updateDataSelectionGrid;
  isGridRendered = false;
  constructor(private selectedGridService: SelectedGridService) {
    this.dataLoadingText = appConstant.DATA_FOUND_LOADING;
  }
  ngOnDestroy(): void {
    this.gridSubscription.unsubscribe();
  }

  ngOnInit() {
    this.isLoading = true;
    this.gridSubscription = this.selectedGridService
      .getSelectedItemSubject()
      .subscribe((data) => {
        this.updateDataSelection(data);
      });
  }

  ngOnChanges(changes) {
    if (changes && changes.data && changes.data.currentValue) {
      this.formatItemTypeAndUpdateIsToday(changes.data.currentValue);
      this.findTodaysAndOlder(changes.data.currentValue);

      if (this.data.length === 0) {
        this.dataLoadingText = appConstant.NO_DATA_FOUND;
      }
      this.isCheckedAll = false;
      this.selectedElement = [];
      if (this.sortByValue && this.sortByValue !== '') {
        this.isAllowGrouping = this.groupingOn.includes(this.sortByValue);
      }
    }

    if (
      changes &&
      changes.tabConfiguration &&
      changes.tabConfiguration.currentValue
    ) {
      this.tabConfiguration = changes.tabConfiguration.currentValue;
      this.sortBy = this.tabConfiguration.sortByOptions;
      this.groupingOn = this.tabConfiguration.groupingOn;
      this.orderByValue = this.tabConfiguration.defaultOrderBy;
      this.allowGroupingOnSort();
    }
  }
  formatItemTypeAndUpdateIsToday(data) {
    if (data && data.length > 0) {
      data.forEach((item) => {
        item.itemType = item.itemType.toUpperCase();
        if (item.isTodayFilterDate !== '0001-01-01T00:00:00') {
        const todayDate = moment(new Date()).format('DD-MM-YYYY');
        const docFilterDate = moment(new Date(`${item.isTodayFilterDate}Z`)).format('DD-MM-YYYY');
        if (todayDate === docFilterDate) {
            item.isToday = true;
          } else {
            item.isToday = false;
          }
        }
      });
      this.data = data;
    }
  }

  openPropertiesNav(item) {
    if (item) {
      this.isShowPropertyTab = true;
      this.propertyWrapper = this.propertyComponent[item.itemType];
      this.propertyDiscription = item;
      this.tabDiscriptions.emit(item);
      this.propertyPanelOpened.emit({
        isPropertyTabShown: this.isShowPropertyTab,
      });
    } else {
      this.closePropertiesNav();
    }
  }

  updateProperties(item, i) {
    this.selectedTab = i;
    if (this.isShowPropertyTab) {
      this.propertyWrapper = this.propertyComponent[item.itemType];
      this.propertyDiscription = item;
      this.tabDiscriptions.emit(item);
    }
    this.gridItemSelected.emit({
      selectedItem: item,
      selectedItemIndex: i,
      isPropertyTabShown: this.isShowPropertyTab,
    });
  }

  updateDataSelection(data) {
    this.updateDataSelectionGrid = data;
  }
  closePropertiesNav() {
    this.isShowPropertyTab = false;
    this.propertyPanelClosed.emit({
      isPropertyTabShown: this.isShowPropertyTab,
    });
  }

  findTodaysAndOlder(data) {
    this.isShowPropertyTab = false;
    this.todaylistIndex = data.findIndex((elmt) => {
      return elmt.isToday;
    });

    this.olderListIndex = data.findIndex((elmt) => {
      return !elmt.isToday;
    });
    if (this.updateDataSelectionGrid) {
      this.selectedTab = this.updateDataSelectionGrid.selectedItemIndex;
      if (this.selectedTab >= 0) {
        this.isShowPropertyTab = this.updateDataSelectionGrid.isPropertyTabShown;
        this.propertyDiscription = this.updateDataSelectionGrid.selectedItem;
        if (this.isShowPropertyTab) {
          this.openPropertiesNav(this.propertyDiscription);
        }
      } else {
        this.isShowPropertyTab = false;
        this.propertyDiscription = null;
      }
    }
  }
  scrollSelectedItemToTop(itemGuid: string) {
    const selectedRecord = document.getElementById(itemGuid);
    selectedRecord.scrollIntoView(false);
  }
  getSelectedValue($event, eventname?: string, index?: number) {
    if (eventname === 'All') {
      if ($event.target.checked) {
        this.data.forEach((elmt) => {
          elmt.isChecked = true;
        });
        this.selectedElement = this.data;
      } else {
        this.data.forEach((elmt) => {
          elmt.isChecked = false;
        });
        this.selectedElement = [];
      }
    } else {
      this.data[index].isChecked = $event.target.checked;
      this.selectedElement = this.data.filter(
        (item) => item.isChecked === true
      );
    }
    if (this.selectedElement.length === this.data.length) {
      this.isCheckedAll = true;
    } else {
      this.isCheckedAll = false;
    }
  }

  onSortedBy($event) {
    this.sortByValue = $event.target.value;
    const obj = { sortBy: this.sortByValue, orderBy: this.orderByValue };
    this.sortedBy.emit(obj);
  }

  getOrderBy(value) {
    this.orderByValue = this.orderByValue ? 0 : 1;
    const obj = { sortBy: this.sortByValue, orderBy: this.orderByValue };
    this.sortedBy.emit(obj);
  }

  downloadSelectedItem() {
    this.downloadSelectedItems.emit(this.selectedElement);
  }

  loadMoreData(event) {
    this.loadMore.emit(event);
  }

  getFileExtention(type: string) {
    return Utils.getFileExtention(type);
  }

  allowGroupingOnSort() {
    if (this.sortBy && this.sortBy.length >= 1) {
      this.sortBy.forEach((elmt) => {
        if (elmt.selected) {
          this.sortByValue = elmt.value;
        }
      });
      this.isAllowGrouping = this.groupingOn.includes(this.sortByValue);
    }
  }

  onDocumentClick(item) {
    this.gridItemClicked.emit(item);
  }

  onDownloadEmailDocument(item, eventType) {
    const selectedData = {
      data: item,
      event: eventType,
    };
    this.downloadEmailDocument.emit(selectedData);
  }
  openPopupMenu(num: string) {
    setTimeout(() => {
      const popoverElement = document.getElementById(
        'propertiesPopupMenu' + num
      );
      const gridHeight = this.grid.nativeElement.clientHeight;
      const transform = popoverElement.getBoundingClientRect();
      const yPos = transform.top;
      const elementHeight = transform.height;
      const diff = gridHeight - (yPos + elementHeight - 200);
      if (diff < 0) {
        popoverElement.style.top = `${-elementHeight + 10}px`;
      } else {
        popoverElement.style.top = '30px';
      }
    }, 10);
  }
}
