<div class="row m-0">
  <div class="col-12 p-0">
    <a class="used-link" data-toggle="collapse" data-target="#where-used"
      >Where Used</a
    >
    <span id="where-used" class="show">Show Locations</span>
    <span id="where-used" class="collapse">Hide Locations</span>
  </div>

  <div id="where-used" class="row m-0 collapse show-workspace">
    <div class="where-used" *ngIf="workSpaceData"> 
      <ul *ngIf="workSpaceData.length">
        <li *ngFor="let workspace of workSpaceData" class="work-box" >
            <label class="location-img" ><img src="../../../assets/images/searchMatter.svg"/></label>
            <span type="text" class="location-text" [ngClass]="{'location-text1': !workspace.folderList.length, 
            'matter-width': (workspace.folderList.length ==1), 
            'matter-width1': (workspace.folderList.length >= 2) }" [routerLink]="['/dashboard/matters', libraryGuid, workspace.matterGuid]" [title]="workspace.matterName">{{workspace.matterName}}</span>
            <ng-container *ngFor="let folder of workspace.folderList">
            <label class="location-img folder-img" ><img  width="20" src="../../../assets/images/folder.svg"/></label>
            <span type="text" class="location-text folder-text" [ngClass]="{'location-text1': !workspace.folderList.length, 
            'matter-width': (workspace.folderList.length ==1),
            'matter-width1': (workspace.folderList.length >= 2) }" (click)="navigateToFolder(libraryGuid, workspace.matterGuid, folder.folderGuid)" [title]="folder.folderName">{{ folder.folderName }}</span>
          </ng-container>
        </li>
      </ul>
      
  </div>
  <div class="col-12 p-0" *ngIf="!workSpaceData">
          <span>No location found.</span>
  </div>
  </div>
</div>
