import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { IPropertiesReq } from '../common/models/properties';
import { configJSON } from '../appConfig';
import {
  ResponseModel,
  DocumentRequest,
  DocumentResponseModel, WorkSpaceSecurityResponseModel,
  EmailResponseModel,
  EmailRequest,
  MatterResponseModel,
  SelectedMatterFolderResponse,
  WorkspaceResponseModel,
} from '../common/models';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  private dashboardServiceSubject = new Subject<any>();
  constructor(private http: HttpClient) {}

  getDocumentData(
    requestBody: DocumentRequest
  ): Observable<DocumentResponseModel> {
    return this.http.post<DocumentResponseModel>(
      configJSON.getDocumentData,
      requestBody,
      {withCredentials: true}
    );
  }
  setSubject(data: any) {
    this.dashboardServiceSubject.next(data);
  }
  clearSubject() {
    this.dashboardServiceSubject.next();
  }
  getSubject(): Observable<any> {
    return this.dashboardServiceSubject.asObservable();
  }

  getProperties(
    requestBody: IPropertiesReq
  ): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(
      configJSON.getProperties,
      requestBody,
      {withCredentials: true}
    );
  }

  getMatterData(requestBody): Observable<MatterResponseModel> {
    return this.http.post<MatterResponseModel>(configJSON.getMatter, requestBody,{withCredentials: true});
  }

  getEmailData(requestBody): Observable<EmailResponseModel> {
    return this.http.post<EmailResponseModel>(configJSON.getEmailData, requestBody,{withCredentials: true});
  }

  getEmailProperties(requestBody): Observable<EmailResponseModel> {
    return this.http.post<EmailResponseModel>(configJSON.getEmailProperties, requestBody,{withCredentials: true});
  }

  getMatterProperties(requestBody): Observable<MatterResponseModel> {
    return this.http.post<MatterResponseModel>(configJSON.getMatterProperties, requestBody,{withCredentials: true});
  }

  getFolderData(requestBody): Observable<SelectedMatterFolderResponse> {
     return this.http.post<SelectedMatterFolderResponse>(configJSON.getFolderList, requestBody,{withCredentials: true});
  }

  getFolderProperty(requestBody): Observable<SelectedMatterFolderResponse> {
    return this.http.post<SelectedMatterFolderResponse>(configJSON.getFolderProperty, requestBody,{withCredentials: true});
  }

  getAllNestedFolderData(requestBody): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(configJSON.getAllNestedFolder, requestBody,{withCredentials: true});
  }

  getParentListInfo(requestBody): Observable<WorkspaceResponseModel> {
    return this.http.post<WorkspaceResponseModel>(configJSON.getParentListInfo, requestBody,{withCredentials: true});
  }

  getWorkspaceData(requestBody, isFolder): Observable<ResponseModel> {
    const requestURL = isFolder
      ? configJSON.getParentListInfo
      : configJSON.getWorkspaceList;
    return this.http.post<ResponseModel>(requestURL, requestBody,{withCredentials: true});
  }
  getWorkspaceSecurityData(
    requestBody,
    isMatterOrFolder?: boolean
  ): Observable<WorkSpaceSecurityResponseModel> {
    const requestURL = isMatterOrFolder
      ? configJSON.getMatterFolderSecurityDataUrl
      : configJSON.getWorkspaceSecurityDataUrl;
    return this.http.post<WorkSpaceSecurityResponseModel>(requestURL, requestBody,{withCredentials: true});
  }

  getMatterDocuments(
    requestBody: DocumentRequest
  ): Observable<DocumentResponseModel> {
    return this.http.post<DocumentResponseModel>(
      configJSON.getMatterDocument,
      requestBody,
      {withCredentials: true}
    );
  }

  getMatterEmails(requestBody: EmailRequest): Observable<EmailResponseModel> {
    return this.http.post<EmailResponseModel>(
      configJSON.getMatterEmails,
      requestBody,
      {withCredentials: true}
    );
  }

  getMatterAllFiles(requestBody): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(configJSON.getMatterAllFiles, requestBody,{withCredentials: true});
  }
}
