export class GridConfigurationModel {
    recentDoc: string;
    labels: GridLabel;
    groupingOn: Array<string>;
    sortByOptions: GridSortByOption[];
    defaultSortBy: string;
    defaultOrderBy: number;
    defaultTotalCount: number;
    isSelectable: boolean;
}

export class GridLabel {
    today: string;
    lastWeek: string;
}

export class GridSortByOption {
    displayName: string;
    value: string;
    selected: boolean;
}

export class GridListItemConfiguration {
    DOCUMENT: ItemConfiguration;
    EMAIL: ItemConfiguration;
    FOLDER: ItemConfiguration;
    MATTER: ItemConfiguration;
    CLIENT: ItemConfiguration;
}

export class ItemConfiguration {
    isclickable: boolean;
    isPropertyTabRequired: boolean;
    isExtentionsRequired: boolean;
    isReceiveByEmail: boolean;
}
