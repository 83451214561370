export class SelectedMatterRequest {
  matterId: string;
  search: Search;
  listType: string;
  orderBy: string;
  orderByAsc: number;
  totalNo: number;
  idCursor?: string;
  sortCursor?: string;
}

// TODO: Search Class will modify once search story in place
class Search {}

export class SelectedMatterFolderResponse {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: DataList;
  dataEnumerable: any[] = [];
  errors: any[] = [];
}

export class DataList {
  isEOF: boolean;
  totalCount: number;
  searchParam: string;
  filterParams: string;
  folderList: FolderDataList[];
}

export class FolderDataList {
  docName: string;
  isToday: boolean;
  itemType: string;
  docURLPath: string;
  description: FolderDescription;
  folderProperties: null;
}

export class FolderDescription {
  folderNumber?: number;
  containeId?: number;
  docId: string;
  owner: string;
  createDate: string;
  editDate: string;
  docExtension: string;
}

export class FolderPropertyRequestModel {
  matterNumber: number;
  libraryGuid: string;
  folderGuid: string;
}
export class FolderItemStorageModel {
  requestModel: any = {};
  selectedFolderItem: any = {};
  selectedFolderItemIndex: number;
  isPropertyTabShown: boolean;
  constructor() {
    this.selectedFolderItemIndex = -1;
    this.isPropertyTabShown = false;
  }
}

export class FolderRequestModel {
  containerWorkId: number;
  search: Search;
  listType: string;
  libraryGuid: string;
  orderBy: string;
  orderByAsc: number;
  sortCursor?: string;
  idCursor?: string;
  totalNumber: number;
}
