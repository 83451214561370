import { DatabaseList } from './document';

export class EmailRequest {
  emailsFilter: EmailFilter;
  search: IEmailSearch;
  listType: string;
  orderBy: string;
  orderByAsc: number;
  totalNo: number;
  idCursor?: string;
  sortCursor?: string;
  containerWorkId?: number;
}

export class EmailFilter {
  emailList: string;
  emailFromTo: string;
  hasAttachment: boolean;
  emailDatabase: DatabaseList[];
}

// export class DatabaseList {
//   libraryId?: string;
// }

// TODO: Search Class will modify once search story in place
export class IEmailSearch {}

export class EmailResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: EmailList;
  dataEnumerable: any[] = [];
  errors: any[] = [];
}

export class EmailList {
  isEOF: boolean;
  totalCount: number;
  searchParam: string;
  filterParams: string;
  emailList?: EmailDataList[];
  emailDocumentList?: EmailDataList[];
}

export class EmailDataList {
  name: string;
  isToday: boolean;
  itemType: string;
  urlPath: string;
  description: IEmailDescription;
  emailProperties: EmailProperties;
}

export class IEmailDescription {
  number: number;
  from: string;
  to: string;
  size: string;
  docId: string;
  filedDate: string;
  receivedDate: string;
  docExtension: string;
  latestVersion: number;
}

export class EmailProperties {
  emailSubject: string;
  to: string;
  from: string;
  cc: string;
  attachments: number;
  client: string;
  matter: string;
}
export class EmailStorageModel {
  requestModel: EmailRequest;
  selectedEmail: any = {};
  selectedEmailIndex: number;
  isPropertyTabShown: boolean;
  constructor() {
    this.selectedEmailIndex = -1;
    this.isPropertyTabShown = false;
  }
}
