export class WorkspaceResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  data: WorksapceData;
  dataEnumerable: any;
  errors: any[] = [];
}
export class WorksapceData {
  docNum: number;
  docName: string;
  docType: string;
  locationsList: LocationsList[];
}

export interface LocationsList {
  matterId: number;
  matterName: string;
  FolderList: FolderList[];
}

export class FolderList {
  folderId: number;
  FolderName: string;
  FolderLevel: number;
}
