import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ResponseModel } from '../common/models/responseModel';
import { configJSON } from '../appConfig';
import { AuthenticateUserRequestModel } from '../common/models/authenticateUserRequestModel';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}
  public loginServiceSubject = new Subject<any>();
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  setSubject(data: any) {
    this.loginServiceSubject.next(data);
  }
  clearSubject() {
    return this.loginServiceSubject.next();
  }
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  getSubject(): Observable<any> {
    return this.loginServiceSubject.asObservable();
  }
  getAccessCode(
    email: string
  ): Observable<ResponseModel> {
    const requestBody = { emailAddress: email};

    return this.http.post<ResponseModel>(configJSON.getAccessCode, requestBody,{withCredentials: true});
  }
  authenticateUser(
    request: AuthenticateUserRequestModel
  ): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(
      configJSON.validateAccessCode,
      request,
      {withCredentials: true}
    );
  }
  getUser(): Observable<ResponseModel> {
    return this.http.get<ResponseModel>(configJSON.getUser,{withCredentials: true});
  }

  logout(): Observable<ResponseModel> {
    const requestBody = { };
    return this.http.post<ResponseModel>(configJSON.getLogoutUser, requestBody,{withCredentials: true});
  }
}
