import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthService } from '../../services';
import { isProduction } from '../../appConfig';
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private authService: AuthService) { }
  // generic method needs to handle different types of errors
  handleError(error: any) {
   if (!isProduction) {
     // tslint:disable-next-line: no-console
     console.log('Error:', error);
   }
   return throwError(error);
  }
}
