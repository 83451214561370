import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { SearchDisplayModel } from 'src/app/common/models/searchDisplayModel';
import { SearchService } from 'src/app/services/search.service';
import {
  SearchModel,
  MatterSearchModel,
  DocumentSearchModel,
  EmailSearchModel,
  SearchCriteriaModel,
} from 'src/app/common/models/searchModel';
import {
  SearchTypeEnum,
  SearchCategoryType,
  SearchPrefixType,
} from 'src/app/common/enum';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {
  RouteEventTypeEnum,
  RouteTypes,
} from 'src/app/common/enum/routeEventType';
import appConstant from 'src/assets/app-text.json';
@Component({
  selector: 'haven-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @ViewChild('search', { read: ElementRef, static: true })
  searchField: ElementRef;
  appConstant;
  searchtypes: SearchDisplayModel[] = [];
  selectedSearchModel: SearchModel = new SearchModel();
  searchCriteria: SearchCriteriaModel[];
  currentSearchCrieteria: string;
  searchLabel: string;
  searchText: string;
  isPopupToBeShown = false;
  isNotSearch = false;
  isSearchSubmit = false;
  isSearch = false;
  isRouteSubscribed = false;
  constructor(
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    private eRef: ElementRef
  ) {}

  ngOnInit() {
    let url = this.router.url;
    this.setSearchType(url);
    this.getSearchItems();
    this.appConstant = appConstant;
    this.isSearch = this.route.snapshot.queryParams.search || false;
    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        (this.router.url === RouteTypes.DOCUMENTS ||
          this.router.url === RouteTypes.EMAILS ||
          this.router.url === RouteTypes.MATTERS)
      ) {
        // Show loading indicator
        url = event.url;
        this.setSearchType(url);
        this.clearSearchOnRoute();
        this.onSearchTypeSelected(this.selectedSearchModel.selectedSearchType);
      }
    });
  }
  onSearchFocus() {
    this.isNotSearch = false;
    this.isPopupToBeShown = true;
    if (!this.isPopupToBeShown) {
      const url = this.router.url;
      this.setSearchType(url);
    }
    this.onSearchTypeSelected(this.selectedSearchModel.selectedSearchType);
  }
  onSearchBlur() {
    this.isNotSearch = true;
    this.isPopupToBeShown = false;
  }
  setSearchType(url) {
    if (url.indexOf(RouteEventTypeEnum.DOCUMENTS) !== -1) {
      this.selectedSearchModel.selectedSearchType = SearchTypeEnum.DOCUMENT;
    } else if (url.indexOf(RouteEventTypeEnum.EMAILS) !== -1) {
      this.selectedSearchModel.selectedSearchType = SearchTypeEnum.EMAIL;
    } else if (url.indexOf(RouteEventTypeEnum.MATTERS) !== -1) {
      this.selectedSearchModel.selectedSearchType = SearchTypeEnum.MATTER;
    } else {
      this.selectedSearchModel.selectedSearchType = SearchTypeEnum.DOCUMENT;
    }
  }
  clearSearchOnRoute() {
    if (this.router.url.indexOf('search=true') === -1) {
      this.clearSearchContent();
      this.clearSearchData();
    }
  }
  onSearchTypeSelected(searchType) {
    if (this.selectedSearchModel.selectedSearchType !== searchType) {
      this.clearSearchContent();
    }
    this.selectedSearchModel.selectedSearchType = searchType;
    this.searchCriteria = this.searchtypes.filter(
      (x) => x.displayText === searchType
    )[0].searchCriteria;
  }
  clearSearchContent() {
    this.searchText = '';
    this.searchLabel = '';
    this.selectedSearchModel.documentSearchModel = new DocumentSearchModel();
    this.selectedSearchModel.emailSearchModel = new EmailSearchModel();
    this.selectedSearchModel.matterSearchModel = new MatterSearchModel();
    this.currentSearchCrieteria = '';
  }
  getSearchItems() {
    this.isSearchSubmit = false;
    this.searchText = '';
    this.searchtypes = this.searchService.getAllSearchItems();
    this.searchCriteria = this.searchtypes.filter(
      (x) => x.displayText === this.selectedSearchModel.selectedSearchType
    )[0].searchCriteria;

    this.currentSearchCrieteria = '';
  }
  onSearchCategorySelected(searchCategory: SearchCriteriaModel) {
    this.isSearchSubmit = false;
    this.currentSearchCrieteria = searchCategory.displayText;
    switch (searchCategory.displayText) {
      case SearchCategoryType.TITLE:
        this.searchText = SearchPrefixType.TITLE;
        break;
      case SearchCategoryType.AUTHOR:
        this.searchText = SearchPrefixType.AUTHOR;
        break;
      case SearchCategoryType.NUMBER:
        this.searchText = SearchPrefixType.NUMBER;
        break;
      case SearchCategoryType.CLIENT:
        this.searchText = SearchPrefixType.CLIENT;
        break;
      case SearchCategoryType.MATTER:
        this.searchText = SearchPrefixType.MATTER;
        break;
      case SearchCategoryType.SUBJECT:
        this.searchText = SearchPrefixType.SUBJECT;
        break;
    }
    this.searchField.nativeElement.focus();
  }
  clearSearchText() {
    this.isSearchSubmit = true;
    this.isNotSearch = false;
    this.searchText = '';
    this.searchField.nativeElement.focus();
    this.searchService.clearSearchSubject();
    this.currentSearchCrieteria = '';
    this.selectedSearchModel.documentSearchModel = new DocumentSearchModel();
    this.selectedSearchModel.emailSearchModel = new EmailSearchModel();
    this.selectedSearchModel.matterSearchModel = new MatterSearchModel();
  }
  onSearchSubmit() {
    this.isSearchSubmit = true;
    switch (this.selectedSearchModel.selectedSearchType) {
      case SearchTypeEnum.DOCUMENT:
        if (this.searchText === '') {
          this.router.navigate(['dashboard/documents']);
        } else {
          this.router.navigate(['dashboard/documents'], {
            queryParams: { search: true },
          });
          this.searchService.setSearchSubject({
            SearchType: SearchTypeEnum.DOCUMENT,
            data: this.selectedSearchModel.documentSearchModel,
          });
        }
        break;
      case SearchTypeEnum.EMAIL:
        if (this.searchText === '') {
          this.router.navigate(['dashboard/emails']);
        } else {
          this.router.navigate(['dashboard/emails'], {
            queryParams: { search: true },
          });
          this.searchService.setSearchSubject({
            SearchType: SearchTypeEnum.EMAIL,
            data: this.selectedSearchModel.emailSearchModel,
          });
        }
        break;
      case SearchTypeEnum.MATTER:
        if (this.searchText === '') {
          this.router.navigate(['dashboard/matters']);
        } else {
          this.router.navigate(['dashboard/matters'], {
            queryParams: { search: true },
          });
          this.searchService.setSearchSubject({
            SearchType: SearchTypeEnum.MATTER,
            data: this.selectedSearchModel.matterSearchModel,
          });
        }
        break;
      default: {
        break;
      }
    }
    this.isPopupToBeShown = false;
  }
  clearSearchData() {
    switch (this.selectedSearchModel.selectedSearchType) {
      case SearchTypeEnum.DOCUMENT:
        this.searchService.setSearchSubject({
          SearchType: SearchTypeEnum.DOCUMENT,
          data: this.selectedSearchModel.documentSearchModel,
        });
        break;
      case SearchTypeEnum.EMAIL:
        this.searchService.setSearchSubject({
          SearchType: SearchTypeEnum.EMAIL,
          data: this.selectedSearchModel.emailSearchModel,
        });
        break;
      case SearchTypeEnum.MATTER:
        this.searchService.setSearchSubject({
          SearchType: SearchTypeEnum.MATTER,
          data: this.selectedSearchModel.matterSearchModel,
        });
        break;
      default: {
        break;
      }
    }
  }
  isAlphaNumeric(value) {
    const isValid = /^[ A-Za-z0-9_@./#&+-{}*\(\)!$%^~\s|]*$/.test(value);
    return isValid;
  }
  isNumeric(value) {
    const isValid = /^[\d]+$/.test(value);
    return isValid;
  }
  setMatterSearchModel() {
    switch (this.currentSearchCrieteria) {
      case SearchCategoryType.TITLE:
        this.selectedSearchModel.matterSearchModel.searchCriteria = 'title';
        this.selectedSearchModel.matterSearchModel.title = this.searchText.replace(
          SearchPrefixType.TITLE,
          ''
        );
        this.selectedSearchModel.matterSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.TITLE,
          ''
        );
        break;
      case SearchCategoryType.CLIENT:
        this.selectedSearchModel.matterSearchModel.searchCriteria = 'client';
        this.selectedSearchModel.matterSearchModel.client = this.searchText.replace(
          SearchPrefixType.CLIENT,
          ''
        );
        this.selectedSearchModel.matterSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.CLIENT,
          ''
        );
        break;
      case SearchCategoryType.MATTER:
        this.selectedSearchModel.matterSearchModel.searchCriteria = 'matter';
        this.selectedSearchModel.matterSearchModel.matter = this.searchText.replace(
          SearchPrefixType.MATTER,
          ''
        );
        this.selectedSearchModel.matterSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.MATTER,
          ''
        );

        break;
      default: {
        this.selectedSearchModel.matterSearchModel.searchCriteria = '';
        this.selectedSearchModel.matterSearchModel.searchValue = this.isAlphaNumeric(
          this.searchText
        )
          ? this.searchText
          : '';
        this.selectedSearchModel.matterSearchModel.title = this.searchText.replace(
          SearchPrefixType.TITLE,
          ''
        );
        this.selectedSearchModel.matterSearchModel.client = this.searchText.replace(
          SearchPrefixType.CLIENT,
          ''
        );
        this.selectedSearchModel.matterSearchModel.matter = this.searchText.replace(
          SearchPrefixType.MATTER,
          ''
        );

        break;
      }
    }
  }
  setEmailSearchModel() {
    switch (this.currentSearchCrieteria) {
      case SearchCategoryType.NUMBER:
        if (
          this.isNumeric(this.searchText.replace(SearchPrefixType.NUMBER, ''))
        ) {
          this.selectedSearchModel.emailSearchModel.searchCriteria =
            'number';
          this.selectedSearchModel.emailSearchModel.docNumber = parseInt(
            this.searchText.replace(SearchPrefixType.NUMBER, ''),
            10
          );
          this.selectedSearchModel.emailSearchModel.searchValue = this.searchText.replace(
            SearchPrefixType.NUMBER,
            ''
          );
        }
        break;
      case SearchCategoryType.CLIENT:
        this.selectedSearchModel.emailSearchModel.searchCriteria = 'client';
        this.selectedSearchModel.emailSearchModel.client = this.searchText.replace(
          SearchPrefixType.CLIENT,
          ''
        );
        this.selectedSearchModel.emailSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.CLIENT,
          ''
        );
        break;
      case SearchCategoryType.MATTER:
        this.selectedSearchModel.emailSearchModel.searchCriteria = 'matter';
        this.selectedSearchModel.emailSearchModel.matter = this.searchText.replace(
          SearchPrefixType.MATTER,
          ''
        );
        this.selectedSearchModel.emailSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.MATTER,
          ''
        );
        break;
      case SearchCategoryType.SUBJECT:
        this.selectedSearchModel.emailSearchModel.searchCriteria = 'subject';
        this.selectedSearchModel.emailSearchModel.subject = this.searchText.replace(
          SearchPrefixType.SUBJECT,
          ''
        ).trim();
        this.selectedSearchModel.emailSearchModel.searchValue = this.searchText
          .replace(SearchPrefixType.SUBJECT, '')
          .trim();
        break;
      default: {
        this.selectedSearchModel.emailSearchModel.searchCriteria = '';
        this.selectedSearchModel.emailSearchModel.searchValue = this.isAlphaNumeric(
          this.searchText
        )
          ? this.searchText
          : '';
        if (
          this.isAlphaNumeric(this.searchText) &&
          !this.isNumeric(this.searchText)
        ) {
          this.selectedSearchModel.emailSearchModel.client = this.searchText.replace(
            SearchPrefixType.CLIENT,
            ''
          );
          this.selectedSearchModel.emailSearchModel.matter = this.searchText.replace(
            SearchPrefixType.MATTER,
            ''
          );
          this.selectedSearchModel.emailSearchModel.subject = this.searchText.replace(
            SearchPrefixType.SUBJECT,
            ''
          );
        } else if (this.isNumeric(this.searchText)) {
          this.selectedSearchModel.emailSearchModel.docNumber = parseInt(
            this.searchText.replace(SearchPrefixType.NUMBER, ''),
            10
          );
        }
        break;
      }
    }
  }
  setDocumentSearchModel() {
    switch (this.currentSearchCrieteria) {
      case SearchCategoryType.TITLE:
        this.selectedSearchModel.documentSearchModel.searchCriteria = 'title';
        this.selectedSearchModel.documentSearchModel.title = this.searchText.replace(
          SearchPrefixType.TITLE,
          ''
        );
        this.selectedSearchModel.documentSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.TITLE,
          ''
        );
        break;
      case SearchCategoryType.AUTHOR:
        this.selectedSearchModel.documentSearchModel.searchCriteria =
          'authoroperator';
        this.selectedSearchModel.documentSearchModel.authorOperator = this.searchText.replace(
          SearchPrefixType.AUTHOR,
          ''
        );
        this.selectedSearchModel.documentSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.AUTHOR,
          ''
        );
        break;
      case SearchCategoryType.NUMBER:
        if (
          this.isNumeric(this.searchText.replace(SearchPrefixType.NUMBER, ''))
        ) {
          this.selectedSearchModel.documentSearchModel.searchCriteria =
            'number';
          this.selectedSearchModel.documentSearchModel.docNumber = parseInt(
            this.searchText.replace(SearchPrefixType.NUMBER, ''),
            10
          );
          this.selectedSearchModel.documentSearchModel.searchValue = this.searchText.replace(
            SearchPrefixType.NUMBER,
            ''
          );
        }
        break;
      case SearchCategoryType.CLIENT:
        this.selectedSearchModel.documentSearchModel.searchCriteria = 'client';
        this.selectedSearchModel.documentSearchModel.client = this.searchText.replace(
          SearchPrefixType.CLIENT,
          ''
        );
        this.selectedSearchModel.documentSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.CLIENT,
          ''
        );
        break;
      case SearchCategoryType.MATTER:
        this.selectedSearchModel.documentSearchModel.searchCriteria = 'matter';
        this.selectedSearchModel.documentSearchModel.matter = this.searchText.replace(
          SearchPrefixType.MATTER,
          ''
        );
        this.selectedSearchModel.documentSearchModel.searchValue = this.searchText.replace(
          SearchPrefixType.MATTER,
          ''
        );
        break;
      default: {
        this.selectedSearchModel.documentSearchModel.searchCriteria = '';
        this.selectedSearchModel.documentSearchModel.searchValue = this.isAlphaNumeric(
          this.searchText
        )
          ? this.searchText
          : '';
        if (
          this.isAlphaNumeric(this.searchText) &&
          !this.isNumeric(this.searchText)
        ) {
          this.selectedSearchModel.documentSearchModel.title = this.searchText.replace(
            SearchPrefixType.TITLE,
            ''
          );
          this.selectedSearchModel.documentSearchModel.authorOperator = this.searchText.replace(
            SearchPrefixType.AUTHOR,
            ''
          );
          this.selectedSearchModel.documentSearchModel.client = this.searchText.replace(
            SearchPrefixType.CLIENT,
            ''
          );
          this.selectedSearchModel.documentSearchModel.matter = this.searchText.replace(
            SearchPrefixType.MATTER,
            ''
          );
        } else if (this.isNumeric(this.searchText)) {
          this.selectedSearchModel.documentSearchModel.docNumber = parseInt(
            this.searchText.replace(SearchPrefixType.NUMBER, ''),
            10
          );
        }

        break;
      }
    }
  }
  onSearchChange(event) {
    this.isSearchSubmit = false;
    this.isNotSearch = false;
    this.selectedSearchModel.documentSearchModel = new DocumentSearchModel();
    this.selectedSearchModel.emailSearchModel = new EmailSearchModel();
    this.selectedSearchModel.matterSearchModel = new MatterSearchModel();
    if (event === '') {
      this.clearSearchText();
    } else {
      switch (this.selectedSearchModel.selectedSearchType) {
        case SearchTypeEnum.DOCUMENT:
          this.setDocumentSearchModel();
          break;
        case SearchTypeEnum.EMAIL:
          this.setEmailSearchModel();
          break;
        case SearchTypeEnum.MATTER:
          this.setMatterSearchModel();
          break;
        default: {
          break;
        }
      }
    }
  }
  @HostListener('document:click', ['$event'])
  handleOutsideClick(event) {
    if (this.eRef.nativeElement.contains(event.target) === false) {
      this.isNotSearch =
        !this.isSearchSubmit && this.searchText !== '' ? true : false;
      if (
        !event.target.currentSrc ||
        event.target.currentSrc.indexOf('/cross-icn-search.svg') < 0
      ) {
        this.isPopupToBeShown = false;
      }
    }
  }
}
