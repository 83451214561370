export enum eventTypeEnum {
    LOADMORE = 'loadMore',
    EDITDATE = 'editDate',
    CREATDATE = 'createDate',
    NAME = 'name',
    FILEDDATE = 'filedDate',
    RECEIVEDDATE = 'receivedDate',
    FROM = 'from',
    TO = 'to',
  }


export enum versionEnum {
    VERSION = 1
}
