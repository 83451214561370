import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'haven-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
})
export class AppLoaderComponent implements OnInit {
  @Input()
  loaderEnabled = false;
  constructor() {}

  ngOnInit() {}
}
