<div class="row m-0">
  <!-- <div class="col-4 p-0 title-text">
    <span class="security-header span-text p-0">{{
      workSpaceConstant.SECURITY_HEADER_TITLE
    }}</span>
     <span class="p-0">{{ workSpaceSecurityData.defaultSecurity }}</span> 
  </div> -->
  <div class="col-12 p-0">
    <button
      type="button"
      class="btn_security_details"
      (click)="isSecurityDetailsToBeShown = !isSecurityDetailsToBeShown"
    >
      {{
        isSecurityDetailsToBeShown
          ? workSpaceConstant.BTN_HIDE_SECURITY_TITLE
          : workSpaceConstant.BTN_SHOW_SECURITY_TITLE
      }}
    </button>
  </div>
</div>
<div *ngIf="isSecurityDetailsToBeShown">
  <div class="row m-0">
    <div class="col-12 p-0">
      <span class="user_grps_count_title span-text" *ngIf="workSpaceSecurityData"
        >{{ workSpaceSecurityData.count
        }}{{ workSpaceConstant.USR_GRPS_TITLE }}</span
      >
    </div>
  </div>
  <div class="security-display" *ngIf="workSpaceSecurityData && workSpaceSecurityData.itemSecurities && workSpaceSecurityData.itemSecurities.length>0">
    <div
      class="row m-0 user_grp_record"
      *ngFor="let usrgrp of workSpaceSecurityData.itemSecurities"
    >
      <div class="col-8 p-0 user_grp_text">
        <span class="user_text text-overflow" title="{{ usrgrp.userGrpId }}">{{ usrgrp.userGrpId }}</span><span class="user_text text-overflow"> | </span>
        <span class="user_text text-overflow" title="{{ usrgrp.userGrpFullName }}">{{ usrgrp.userGrpFullName }}</span>
      </div>
      <div class="col-4 p-0 user_grp_permission">
        <span class="user_permission_text" title="{{ usrgrp.name }}">{{ usrgrp.name }}</span>
      </div>
    </div>
  </div>
</div>
