import { Injectable } from '@angular/core';
import {ListTypeEnum} from '../common/enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}
  // storage data can be of any data type we can store JSON , string , date  number or boolean
  setLocalStorage(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  clearLocalStorageByKey(key: ListTypeEnum) {
    localStorage.removeItem(key);
  }
}
