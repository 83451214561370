export class ResponseModel {
  apiResponseStatus: string;
  httpStatus: number;
  message: string;
  // data can be the array of different type of object depend on the api call
  data: any;
  // dataEnumerable can be the array of different type of object depend on the api call
  dataEnumerable: any[] = [];
  // errors can be the array of different type of object depend on the api call
  errors: any[] = [];
}
