import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { configJSON } from '../../appConfig';
import { LibraryResponseModel, LibraryReuestModel } from 'src/app/common/models/librarylist';

@Injectable({
  providedIn: 'root',
})
export class LeftPanelLibrariesService {
  constructor(private http: HttpClient) {}

  public getLibrariesDataList(): Observable<LibraryResponseModel> {
    return this.http.post<LibraryResponseModel>(
      configJSON.getLibrariesData,
      {},
      {withCredentials: true}
    );
  }

  public getConnectionLibrariesDataList(connectionGuid): Observable<LibraryResponseModel> {
    const libraryRequest  = new LibraryReuestModel();
    libraryRequest.connectionGuid = connectionGuid;
    return this.http.post<LibraryResponseModel>(
      configJSON.getLibraryByConnection,
      libraryRequest,
      {withCredentials: true}
    );
  }
}
