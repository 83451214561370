export enum ListTypeEnum {
  DOCUMENTS = 'Documents',
  MATTERS = 'Matters',
  DOCUMENT = 'Document',
  MATTER = 'Matter',
  CLIENTS = 'Clients',
  FOLDERS = 'Folders',
  EMAIL = 'Email',
  MATTER_DOC = 'Matter_doc',
  MATTER_EMAIL = 'Matter_email',
  MATTER_ALL = 'Matter_all',
}

