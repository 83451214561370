import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Library } from 'src/app/common/models';
import { ListTypeEnum} from '../../common/enum/';
import { LocalStorageService } from '../../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SharedLeftpanelFilterService {
  filterList = {};
  libraryListKey = 'LibraryList';
  constructor(private localStorageService: LocalStorageService) {
  }

  storeSelectedFilterList(data, filterType: ListTypeEnum) {
    this.filterList[filterType] = data;
    this.storeSelectedLibraryList(data);
    this.localStorageService.setLocalStorage(filterType, data);

  }

  getSelectedFilterList(filterType: ListTypeEnum) {
    if (this.filterList[filterType]) {
      return this.filterList[filterType];
  } else {
    this.filterList[filterType] = this.localStorageService.getLocalStorage(filterType);
    return this.filterList[filterType];
  }
  }

  getSelectedLibraryListFromLocal(filterType: ListTypeEnum) {
    if (this.getSelectedFilterList(filterType)) {
    return  this.filterList[filterType].filter((e) => e.name === 'Database');
  } else {
    return null;
  }
  }
  getSelectedLibraryList(filterType: ListTypeEnum) {
   let libraryList = this.filterList[this.libraryListKey];
   if (!libraryList) {
    libraryList = this.getSelectedLibraryListFromLocal(filterType);
    this.filterList[this.libraryListKey] = libraryList;
   }
   return libraryList;
  }
  storeSelectedLibraryList(filterData) {
    if (filterData) {
      const libraryList =  filterData.filter((e) => e.name === 'Database');
      if (libraryList && libraryList.length > 0 ) {
      this.filterList[this.libraryListKey] = libraryList;
      }
  }
  }

  getLibraryName(filterType: ListTypeEnum, libraryGuid: string) {
    let libraryName = 'No-Name Library';
    const libraryList =  this.getSelectedLibraryList(filterType);
    if (libraryList && libraryList.length > 0) {
      const selectedLibrary =   libraryList[0].value.filter((e) => e.libraryGuid === libraryGuid);
      if (selectedLibrary && selectedLibrary.length > 0) {
        libraryName = selectedLibrary[0].name;
      }
  }
    return libraryName;
  }



}
