import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { LeftPanelComponent } from './left-panel/left-panel.component';
import { CustomGridComponent } from './custom-grid/custom-grid.component';
import { FormsModule } from '@angular/forms';
import { DynamicModule } from 'ng-dynamic-component';
import { FooterComponent } from './footer/footer.component';
import { SearchComponent } from './search/search.component';
import { OnlyAlphaNumericDirective } from './only-alpha-numeric.directive';
import { AppTimerComponent } from './app-timer/app-timer.component';
import { WorkspacePropertiesComponent } from './workspace-properties/workspace-properties.component';
import { WorkspaceSecurityAccessorsComponent } from './workspace-security-accessors/workspace-security-accessors.component';
import { LocalDatePipe } from '../common/pipe/locale.time';

@NgModule({
  declarations: [
    HeaderComponent,
    AppLoaderComponent,
    ConfirmationDialogComponent,
    MenuComponent,
    LeftPanelComponent,
    CustomGridComponent,
    FooterComponent,
    AppTimerComponent,
    SearchComponent,
    OnlyAlphaNumericDirective,
    WorkspacePropertiesComponent,
    WorkspaceSecurityAccessorsComponent,
    LocalDatePipe
  ],
  imports: [CommonModule, RouterModule, FormsModule, DynamicModule],
  providers: [],
  exports: [
    HeaderComponent,
    AppLoaderComponent,
    ConfirmationDialogComponent,
    MenuComponent,
    LeftPanelComponent,
    CustomGridComponent,
    FooterComponent,
    AppTimerComponent,
    WorkspacePropertiesComponent,
    WorkspaceSecurityAccessorsComponent,
    LocalDatePipe
  ],
})
export class SharedModule {}
