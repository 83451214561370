<nav class="navbar navbar-expand-lg navbar-light bg-dark-gray">

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarCollapse"
    aria-controls="navbarCollapse"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <span class="nav-title-mobile"><b>Recent Documents</b><br />6 Items</span>

  <div class="collapse navbar-collapse bg-blue" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" *ngFor="let menu of menus">
        <a
          class="nav-link nav-link-active-mobile"
          routerLink="{{ menu.Url }}"
          routerLinkActive="active"
          *ngIf="!menu.Hide"
           #rla="routerLinkActive"
        >
          <span><img width="30" src="{{ rla.isActive? menu.ActiveImg: menu.Img }}"/></span
          >{{ menu.DisplayName }}<span class="sr-only">(current)</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
