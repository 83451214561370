import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  config = {
    closeButton: true,
    timeOut: 3000,
    extendedTimeOut: 1000,
    easing: 'ease-out',
    easeTime: 1000,
    disableTimeOut: false,
    progressBar: true,
    tapToDismiss: true,
    enableHtml: true,
  };
  constructor(private messenger: ToastrService) {}
  public notificationServiceSubject = new Subject<any>();
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  setSubject(data: any) {
    this.notificationServiceSubject.next(data);
  }
  clearSubject() {
    return this.notificationServiceSubject.next();
  }
  // All the methods which are required for set or get a subject can have different types of data
  // Maybe Object ,string , numeric or boolen, string
  getSubject(): Observable<any> {
    return this.notificationServiceSubject.asObservable();
  }
  success(message?: string, title?: string) {
    this.messenger.success(message, title, this.config);
  }
  info(message?: string, title?: string) {
    this.messenger.info(message, title, this.config);
  }
  warn(message?: string, title?: string) {
    this.messenger.warning(message, title, this.config);
  }
  error(message?: string, title?: string) {
    this.messenger.error(message, title, this.config);
  }
}
