export enum FilterTypeEnum {
  DATABASE = 'Database',
  FOLDER = 'Views',
  DOCUMENT = 'AllDocument',
}
export enum ChildTypeEnum {
  FOLDERS = 'folders',
}

export enum SelectTypeEnum {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}
